<template>
  <div class="introduction">
    <div class="bigTitle">公司简介</div>
    <p class="lineH" style="line-height: 0.425rem;">
      深圳市创势互联科技有限公司（简称“创势互联”）是一家以智慧城市和物联网技术应用为主的智慧软件服务商。公司由从事电子政务十年
      多的核心团队组建而成,目前公司员工已达40余人,并在广西南宁设立的分公司,拥有国家高新技术企业、双软企业、CMMi
      3、ISO9001、 ISO27001等资质。
    </p>
    <p class="lineH" style="line-height: 0.425rem;" >
      公司成立于2016年3月,经过多年的积累沉淀研发的智慧政务、智慧应急、智慧街道、智慧园区四大体系产品,是新型智慧城市的重要建设
      内容,拥有二十多个软件著作权,并于2018年通过CMMI3的资质评估,拥有一套高效的项目研发实施流程规范,并成功在多个城市的政府
      部门实施。
    </p>
    <p class="lineH" style="line-height: 0.425rem;">
      经过团队多年来奋斗努力,依靠应用集成的服务创新,软件实施流程的科学管理,基于大数据、物联网等重要技术,快速发展壮大,公司已
      经成功的实施了50余个智慧城市类项目,业务类型涵盖政府投资项目管理系统、发改综合业务管理系统、重大项目管理系统、智慧街道综合
      管理系统平台、智慧消防管理平台、智慧后勤综合管理平台、信息化项目全流程管理系统等,客户包括了大数据局、发改局、文体局、经促
      局、街道办、电厂、监狱等各类政府机关事业单位,实施的过程和结果均得到客户一致好评。
    </p>
    <img src="@/assets/images/introduction.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.introduction {
  p {
    margin: 0.3125rem 0;
    font-size: 0.2rem;
    color: #666666;
  }

  img {
    width: 100%;
  }

  .bigTitle {
    font-size: 0.375rem;
    font-weight: bold;
    color: #04468D;
  }
}
</style>