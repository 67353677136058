<template>
  <div id="custMainContent">
    <!-- <div class="topBanner">
      <div class="topTitle"> -->
        <!-- <img src="@/assets/newImages/CustBannerTitle.png" alt="" /> -->
      <!-- </div> -->
      <!-- <div class="topImg"> -->
        <!-- <img src="@/assets/newImages/CustBannerImg.png" alt="" /> -->
      <!-- </div> -->
    <!-- </div> -->
    <div style="width: 100%; background-color: white">
      <div class="serialNumBox" id="serial1">
        <div class="mainBox">
          <div class="lineblue">
            <div class="circle"></div>
          </div>
          <div class="textBox">
            <div class="topTitle">
              <div class="num">
                <img src="@/assets/newImages/custNum01.png" alt="" />
              </div>
            </div>
            <div class="midIcon">
              <div class="iconBox">
                <div class="iconPng">
                  <img src="@/assets/newImages/shangwu.png" alt="" />
                </div>
                <div class="iconText">商务接洽</div>
              </div>
              <div class="iconBox">
                <div class="iconPng">
                  <img src="@/assets/newImages/xuqiu.png" alt="" />
                </div>
                <div class="iconText">需求调研</div>
              </div>
              <div class="iconBox">
                <div class="iconPng">
                  <img src="@/assets/newImages/fangan.png" alt="" />
                </div>
                <div class="iconText">技术方案</div>
              </div>
            </div>
            <div class="btmList">
              <div class="titleBar">
                <div class="titleLine">
                  <div class="num">01</div>
                  <div class="text font16">专业的商务人员提供咨询接洽</div>
                </div>
              </div>
            </div>
            <div class="btmList">
              <div class="titleBar">
                <div class="titleLine">
                  <div class="num">02</div>
                  <div class="text font16">细致的调研人员进行需求分析</div>
                </div>
              </div>
            </div>
            <div class="btmList">
              <div class="titleBar">
                <div class="titleLine">
                  <div class="num">03</div>
                  <div class="text font16">依托强大技术的背景，设计技术方案</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mainBox">
          <div class="imgBox">
            <img src="@/assets/newImages/custImg1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; background-color: #f4f7fc">
      <div class="serialNumBox" id="serial2" style="background-color: #f4f7fc">
        <div class="mainBox">
          <div class="lineblue">
            <div class="circle"></div>
          </div>
          <div class="textBox">
            <div class="topTitleSec">
              <div class="num">
                <img src="@/assets/newImages/custNum02.png" alt="" />
              </div>
            </div>
            <div class="midIcon">
              <div class="iconBox">
                <div class="iconPng">
                  <img src="@/assets/newImages/kuangjia.png" alt="" />
                </div>
                <div class="iconText">框架搭建</div>
              </div>
              <div class="iconBox">
                <div class="iconPng">
                  <img src="@/assets/newImages/kaifa.png" alt="" />
                </div>
                <div class="iconText">系统开发</div>
              </div>
              <div class="iconBox">
                <div class="iconPng">
                  <img src="@/assets/newImages/ceshi.png" alt="" />
                </div>
                <div class="iconText">系统测试</div>
              </div>
            </div>
            <div class="btmList">
              <div class="titleBar">
                <div class="titleLine">
                  <div class="num">01</div>
                  <div class="text font16">由经验丰富的系统架构师搭建最适合的框架</div>
                </div>
              </div>
            </div>
            <div class="btmList">
              <div class="titleBar">
                <div class="titleLine">
                  <div class="num">02</div>
                  <div class="text font16">由专业的技术人员进行系统开发</div>
                </div>
              </div>
            </div>
            <div class="btmList">
              <div class="titleBar">
                <div class="titleLine">
                  <div class="num">03</div>
                  <div class="text font16">由严谨的测试部门对系统进行全面测试</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mainBox">
          <div class="imgBox">
            <img src="@/assets/newImages/custImg2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; background-color: white">
      <div class="serialNumBox" id="serial3">
        <div class="mainBox">
          <div class="lineblue">
            <div class="circle"></div>
          </div>
          <div class="textBox">
            <div
              class="topTitleSec"
              style="transform: translateX(-38%) translateY(38%)"
            >
              <div class="num">
                <img style="width: 84%; height: 76%" src="@/assets/newImages/custNum033.png" alt="" />
              </div>
            </div>
            <div class="midIcon">
              <div class="iconBox">
                <div class="iconPng">
                  <img src="@/assets/newImages/gongcheng.png" alt="" />
                </div>
                <div class="iconText">工程咨询规划</div>
              </div>
              <div class="iconBox">
                <div class="iconPng">
                  <img src="@/assets/newImages/quanliucheng.png" alt="" />
                </div>
                <div class="iconText">全流程管理</div>
              </div>
              <div class="iconBox">
                <div class="iconPng">
                  <img src="@/assets/newImages/chanyelian.png" alt="" />
                </div>
                <div class="iconText">产业链协同</div>
              </div>
              <div class="iconBox">
                <div class="iconPng">
                  <img src="@/assets/newImages/xiangmuluodi.png" alt="" />
                </div>
                <div class="iconText">项目落地交付</div>
              </div>
            </div>
            <div class="btmListMax">
              <div class="titleBar">
                <div class="titleLine">
                  <div class="num">01</div>
                  <div class="text font16">
                    由拥有大量集成项目交付经验的团队，
                    设计出切实可行的完整解决方案，
                    然后组织项目各部门协调一致地工作，
                    建立硬件软件集成，最终完成信息化工程
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mainBox">
          <div class="imgBox">
            <img src="@/assets/newImages/custImg3.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; background-color: #f4f7fc">
      <div class="serialNumBox" id="serial4" style="background-color: #f4f7fc">
        <div class="mainBox">
          <div class="lineblue">
            <div class="circle"></div>
          </div>
          <div class="textBox">
            <div
              class="topTitleSec"
              style="transform: translateX(-35%) translateY(35%)"
            >
              <div class="num">
                <img
                  style="width: 80%; height: 70%"
                  src="@/assets/newImages/custNum04.png"
                  alt=""
                />
              </div>
            </div>
            <div class="midIcon">
              <div class="iconBox">
                <div class="iconPng">
                  <img src="@/assets/newImages/shiyunxing.png" alt="" />
                </div>
                <div class="iconText">运行交付</div>
              </div>
              <div class="iconBox">
                <div class="iconPng">
                  <img src="@/assets/newImages/weihu.png" alt="" />
                </div>
                <div class="iconText">后期维护</div>
              </div>
              <div class="iconBox">
                <div class="iconPng">
                  <img src="@/assets/newImages/shenji.png" alt="" />
                </div>
                <div class="iconText">系统升级</div>
              </div>
            </div>
            <div class="btmListMax">
              <div class="titleBar">
                <div
                  class="titleLine"
                  style="height: 1.34rem; margin-top: 1rem"
                >
                  <div class="num">01</div>
                  <div class="text font16">
                    系统运维部门将对系统的试运行、交付、验收、
                    后期维护、系统升级等， 提供全方位的技术支持
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mainBox">
          <div class="imgBox">
            <img src="@/assets/newImages/custImg4.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "custMainContent",
};
</script>
<style lang='less' scoped>
@import "@/assets/styles/mixins.less";

#custMainContent {
  width: 100%;
  // height: 37.5rem;

  .topBanner {
    position: relative;
    .box-config(100%, 4.875rem, "../../assets/newImages/客户服务-背景.png");

    .topTitle {
      // position: absolute;
      // transform: translateX(2.625rem) translateY(1.8125rem);
      // height: 6.25rem;

      // img {
      //   width: 100%;
      //   height: 80%;
      // }
      position: absolute;
      width: 60%;
      height: 100%;
      transform: translateX(25%) translateY(12%);
      background-image: url("../../assets/newImages/关于我们-文字.png");
      background-size: 100% 100%;
    }

    .topImg {
      // position: absolute;
      // transform: translateX(2.875rem) translateY(1.75rem);
      // height: 5.625rem;

      // img {
      //   width: 100%;
      //   height: 100%;
      // }
      position: absolute;
      right: 0;
      transform: translateX(-30%) translateY(6%);
      width: 35%;
      height: 100%;
      background-image: url("../../assets/newImages/客户服务-图形.png");
      background-size: 100% 100%;
    }
  }

  .serialNumBox {
    display: flex;
    width: 60%;
    height: 7.5rem;
    background-color: white;
    justify-content: space-evenly;
    margin: 0 auto;
    transform: translateX(0.75rem);
    .mainBox {
      // width: 11.875rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .lineblue {
        width: 0.0375rem;
        height: 56%;
        transform: translateY(24%);
        background-color: #93aacb;
        position: relative;
        .circle {
          position: absolute;
          top: 46%;
          transform: translateX(-44%);
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 0.25rem;
          background-color: #0e5fb5;
        }
      }
      .imgBox {
        width: 6.5rem;
        height: 5.625rem;
        // display: flex;
        // justify-content: center;
        // align-items: flex-end;
        transform: translateY(10%);

        img {
          width: 100%;
          height: 100%;
        }
      }

      .textBox {
        width: 6.5rem;
        height: 6.25rem;
        box-sizing: border-box;
        padding: 0.25rem 0.75rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .topTitleSec,
        .topTitle {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          transform: translateX(-32%) translateY(50%);

          .num {
            height: 1.625rem;

            img {
              width: 80%;
              height: 70%;
            }
          }
        }

        .topTitleSec {
          transform: translateX(-34%) translateY(54%);

          .num {
            height: 1.625rem;

            img {
              width: 76%;
              height: 60%;
            }
          }
        }

        .midIcon {
          display: flex;
          justify-content: space-between;
          width: 5rem;

          .iconBox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .iconPng {
              width: 0.375rem;
              height: 0.375rem;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .iconText {
              padding-top: 0.0625rem;
              color: #0e5fb5;
              font-size: 0.2rem;
            }
          }
        }

        .btmListMax,
        .btmList {
          .titleBarMax,
          .titleBar {
            width: 100%;
            height: 0.5rem;
            background: url("@/assets/newImages/custTrapezoid.png") no-repeat;
            background-size: 0.625rem 0.5rem;
            display: flex;
            align-items: center;

            .titleLine {
              width: 100%;
              display: flex;
              align-items: center;
              border: 0.0125rem solid #0e5fb5;

              .num {
                padding: 0 0.125rem;
                color: white;
              }

              .text {
                color: black;
                font-size: 0.1875rem;
                padding: 0 0.25rem;
              }
            }
          }
        }

        .btmListMax {
          height: 2.75rem;

          .titleLine {
            height: 1.875rem;
            margin-top: 1.625rem;
            display: flex;
            align-items: flex-start !important;

            .text {
              margin-top: 0.1rem;
              color: black;
              font-size: 0.1875rem;
              line-height: 0.4375rem;
              padding: 0 0.25rem;
            }
          }
        }
      }
    }
  }
}
</style>