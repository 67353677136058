<template>
  <div class="subjectInfo">
    <div class="title">
      <div @click="goback">
        <div class="back-btn">
          <span class="iconfont icon-fanhui"></span>
          <span>返回</span>
        </div>
      </div>
    </div>
    <div class="contentitle">{{ articleData.title || "" }}</div>
    <div class="text">{{ articleData.content }}</div>
    <div class="img" v-for="(item, index) in imgList" :key="index">
      <img :src="item" alt />
    </div>
  </div>
</template>

<script>
import { ImgUrl } from "@/unitils/doMain";
export default {
  data() {
    return {
      articleData: {},
      index: "",
    };
  },
  computed: {
    imgList() {
      let list = [];
      this.articleData.imgs.split(":").forEach((element) => {
        if (element) {
          list.push(ImgUrl + element);
        }
      });
      return list;
    },
  },
  created() {
    this.index = this.$route.params.num;
    this.articleData = this.$route.query;
    window.scrollTo(0, 0);
    // if (Object.keys(this.articleData).length == 0) {
    //   console.log('暂无数据');
    // }
  },
  methods: {
    goback() {
      this.$router.go(-1); //返回上一页
    },
  },
};
</script>

<style lang="less" scoped>
.subjectInfo {
  .title, .contentitle {
    color: #04468d;
    font-weight: bold;
    font-size: 0.375rem;
  }
  .title{
    position: relative;
  }
  .contentitle{
    margin-bottom: 0.3rem;
  }
  .back-btn{
    position: absolute;
    top: 0;
    right: 0;
  }
  .project {
    font-size: 0.25rem;
    border-bottom: 0.0125rem solid #eee;
    padding-bottom: 0.125rem;
    margin: 0.25rem 0;
  }
  .text {
    text-indent: 2em;
    font-size: 0.22rem;
    line-height: 0.375rem;
  }
  .img {
    margin-top: 0.25rem;
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
}
</style>
