<template>
  <div class="pro">
    <!-- <div class="top">
      <div class="slogan"></div>
      <div class="homeImg"></div>
    </div> -->
    <div class="con">
      <div class="left">
        <div class="leftList">
          <div class="left_item" :class="{ active: routeName == '/ProductPlan/Government' }">
            <router-link to="/ProductPlan/Government">
              <p>智慧政务</p>
            </router-link>
          </div>
          <div class="left_item" :class="{ active: routeName == '/ProductPlan/Emergency' }">
            <router-link to="/ProductPlan/Emergency">
              <p>智慧应急</p>
            </router-link>
          </div>
          <div class="left_item" :class="{ active: routeName == '/ProductPlan/Park' }">
            <router-link to="/ProductPlan/Park">
              <p>智慧园区</p>
            </router-link>
          </div>
          <div class="left_item" :class="{ active: routeName == '/ProductPlan/Smart' }">
            <router-link to="/ProductPlan/Smart">
              <p>智能化工程</p>
            </router-link>
          </div>
          <!-- <div class="left_item" :class="{ active: routeName == '/ProductPlan/EntDigitalService' }">
            <router-link to="/ProductPlan/EntDigitalService">
              <p>企业数字化</p>
            </router-link>
          </div> -->
        </div>
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rFlag: 1,
    };
  },
  methods: {},
  // mounted(){
  //   console.log(this.routeName,666);
  // },
  // updated(){
  //   console.log(this.routeName,555);
  // },
  computed: {
    routeName() {
      return this.$route.matched[1].path;
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '../../assets/styles/mixins.styl';

a {
  color: #666666;
}

.active {
  border-right: .0375rem solid #04468D;
  font-weight: bold;

  p {
    color: #04468D !important;
  }
}

.pro {
  background-color: #F8F8FA;
  // min-height: 15rem
}

.top {
  // margin-top: 0.75rem;
  box-config(100%, 4.875rem, '../../assets/newImages/产品方案-背景.png');
  position: relative;

  .slogan {
    position: absolute;
    width: 60%;
    height: 100%;
    transform: translateX(25%) translateY(12%);
    background-image: url('../../assets/newImages/关于我们-文字.png');
    background-size: 100% 100%;
  }

  .homeImg {
    position: absolute;
    right: 0;
    transform: translateX(-10%) translateY(10%);
    width: 38%;
    height: 100%;
    background: url('../../assets/newImages/产品方案-图形.png') no-repeat;
    background-size: 90% 90%;
  }
}

.con {
  display: flex;
  position: relative;
  width: 17.5rem;
  min-width: 13.75rem;
  @media screen and (max-width: 17.5rem) {
    width: 13.75rem;
  }
  transform: translateY(.625rem)
  min-height: 9.5rem;
  margin: 0 auto;
  margin-top: -0.625rem;
  background-color: white;
  border-radius: 0.1rem;
  margin-bottom: 1.25rem

  .left {
    .leftList {
      border-right: .0125rem #EEEEEE solid;
      height: calc(100% - 2rem);
    }

    .left_item {
      margin-right: -0.0187rem;
      width: 2.7875rem;
      height: 0.75rem;
      line-height: 0.75rem;
      margin-top: 0.1875rem;
      font-size: 0.25rem;

      p {
        margin-left: 1rem;
        color: #666666;
      }
    }

    .left_item:nth-child(1) {
      margin-top: 1.1125rem;
    }
  }

  .right {
    width: 12.25rem;
    min-width: 9.875rem;

    @media screen and (max-width: 17.5rem) {
      width: 9.875rem;
    }

    margin-left: 1.225rem;
    margin-top: 1.0625rem;
  }
}
</style>
