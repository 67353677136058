<template>
  <div class="Smart">

    <div class="Smart">
      <div class="project_item" @click="goTop">
        <router-link to="/ProductPlan/Smart/Wiring">
          <img src="@/assets/newImages/Smart_12.png" alt="" />
          <p>综合布线系统</p>
        </router-link>
      </div>

      <div class="project_item" @click="goTop">
        <router-link to="/ProductPlan/Smart/BAControl">
          <img src="@/assets/newImages/Smart_13.png" alt="" />
          <p>BA控制系统</p>
        </router-link>
      </div>

      <div class="project_item" @click="goTop">
        <router-link to="/ProductPlan/Smart/MachineRoom">
          <img src="@/assets/newImages/Smart_14.png" alt="" />
          <p>机房工程</p>
        </router-link>
      </div>

      <!-- <div class="project_item">
        <router-link to="/ProductPlan/Smart/VideoMonitor">
          <img src="@/assets/newImages/Smart_01.png" alt="" />
          <p>高清网络视频监控系统</p>
        </router-link>
      </div>

      <div class="project_item">
        <router-link to="/ProductPlan/Smart/Alarm">
          <img src="@/assets/newImages/Smart_02.png" alt="" />
          <p>周界报警系统</p>
        </router-link>
      </div>

      <div class="project_item">
        <router-link to="/ProductPlan/Smart/Patrol">
          <img src="@/assets/newImages/Smart_03.png" alt="" />
          <p>电子巡更系统</p>
        </router-link>
      </div>

      <div class="project_item">
        <router-link to="/ProductPlan/Smart/TallBack">
          <img src="@/assets/newImages/Smart_04.png" alt="" />
          <p>楼宇对讲系统</p>
        </router-link>
      </div>

      <div class="project_item">
        <router-link to="/ProductPlan/Smart/Verbal">
          <img src="@/assets/newImages/Smart_05.png" alt="" />
          <p>五方通话系统</p>
        </router-link>
      </div>

      <div class="project_item">
        <router-link to="/ProductPlan/Smart/CableTv">
          <img src="@/assets/newImages/Smart_06.png" alt="" />
          <p>有线电视系统</p>
        </router-link>
      </div>

      <div class="project_item">
        <router-link to="/ProductPlan/Smart/VideoConference">
          <img src="@/assets/newImages/Smart_07.png" alt="" />
          <p>多功能会议系统</p>
        </router-link>
      </div>

      <div class="project_item">
        <router-link to="/ProductPlan/Smart/Information">
          <img src="@/assets/newImages/Smart_08.png" alt="" />
          <p>信息发布系统</p>
        </router-link>
      </div>

      <div class="project_item">
        <router-link to="/ProductPlan/Smart/EntranceGuard">
          <img src="@/assets/newImages/Smart_09.png" alt="" />
          <p>门禁管理系统</p>
        </router-link>
      </div>

      <div class="project_item">
        <router-link to="/ProductPlan/Smart/PublicBroadcast">
          <img src="@/assets/newImages/Smart_10.png" alt="" />
          <p>公共广播系统</p>
        </router-link>
      </div>

      <div class="project_item">
        <router-link to="/ProductPlan/Smart/Network">
          <img src="@/assets/newImages/Smart_11.png" alt="" />
          <p>计算机网络系统</p>
        </router-link>
      </div>

      <div class="project_item">
        <router-link to="/ProductPlan/Smart/ParkingLog">
          <img src="@/assets/newImages/Smart_15.png" alt="" />
          <p>停车场管理系统</p>
        </router-link>
      </div> -->

    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goTop() {
      window.scrollTo(0, 0)
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '../../../../assets/styles/mixins.styl';

.Smart {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.375rem;
  justify-content: space-between;

  .project_item {
    margin-bottom: 30px;
    width: 30%;
    font-size: 0.25rem;
    transition .3s;
    &:hover{
      transform:translateY(-10px);
      box-shadow 0 0 10px #0005;
      &:after{
        opacity:1
      }
      p{
        background-color: #2C5EAA;
        color: #fff
      }
    }
    img {
      width: 100%;
      height: auto;
    }

    p {
      height: 0.5rem;
      line-height: 0.5rem;
      box-shadow: 0px 3px 10px 0px rgba(101, 101, 101, 0.11);
      margin-top: -6px;
      text-align: center;
      font-size:0.25rem;
    }
  }
}
</style>
