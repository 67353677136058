<template>
  <div id="container"></div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  data() {
    return {
      map: null,
      longitudeLatitude: [114.23036044082261, 22.686580267976645],
      address: { url: "大运软件小镇39栋3楼", title: "" , img:'gate.jpg'},
      src: "@/assets/images/mark_bs2.png",
    };
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "94fde07e5245897c28478b892d62235f", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Driving"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 16, //初始化地图级别
            center: this.longitudeLatitude, //初始化地图中心点位置
          });

          var marker = new AMap.Marker({
            position: new AMap.LngLat(
              this.longitudeLatitude[0],
              this.longitudeLatitude[1]
            ), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "创势互联科技有限公司",
            // icon: new AMap.Icon({
            //   size: new AMap.Size(16, 27), //图标大小
            //   image: require("@/assets/images/mark_bs2.png"),
            // }),
            content: `<div>
            <div style='width:3.75rem;height: 1rem; background-color:#fff; transform: translateX(-50%);  padding: 0.025rem;'>
              <img style='float: left; width: 1.25rem;height: 100%;'src='${require("@/assets/newImages/"+this.address.img)}' />
              <span style='float: left;color:#000;font-size:0.2rem; margin: 0.0625rem;'>${
                this.address.url
              }</span>
              <span style='float: left;color:#000; font-size: 0.2rem;;  margin: 0.0625rem;'>${
                this.address.title
              }</span>
              <a href="https://www.amap.com/" style='float: right;color:#000;font-size:0.2rem; margin: 0.0625rem;background-color:#3e93fd;color:#fff;padding:0.05rem;border-radius: 0.0625rem;'>出发</a>
            </div>
            <img src='${require("@/assets/images/mark_bs2.png")}'/>
            </div>`,
            offset: new AMap.Pixel(-16, -13.5),
          });

          // 将创建的点标记添加到已有的地图实例：
          this.map.add(marker);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    switchAddress(address) {
      console.log(address);
      if (address == "ln") {
        this.longitudeLatitude = [108.28511116336823, 22.87954372138036];
        this.address = {
          url: "发展大道189号1号B座304",
          title: "南宁分公司",
          img:'nanning.png'
        };
      } else {
        this.longitudeLatitude = [114.23036044082261, 22.686580267976645];
        this.address = {
          url: "大运软件小镇39栋3楼",
          title: "深圳总部",
          img:'gate.jpg'
        };
      }

      this.initMap();
    },
  },

  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  },
  destroyed() {
    this.map.destroy();
    this.map = null;
    // this.mapModule.dispose();
    this.mapModule = null;
    // console.log("==================destroyed==================");
  },
};
</script>

<style  scoped>
#container {
  box-shadow: rgb(204 204 204) 0rem 0rem .0375rem .0037rem;
  padding: 0rem;
  margin: 0rem;
  width: 100%;
  height: 5.625rem;
  
}
</style>