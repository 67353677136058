<template>
  <div class="foot">
    <div class="con">
      <div class="left">
        <img style="width: 2.4rem; margin-left: -0.125rem" src="~@/assets/newImages/companyIconWhite.png" alt="" />
        <div class="phone">
          <img style="margin-right: 0.125rem" src="../../assets/newImages/phonefoot.png" alt="" />服务热线：0755-28248402
        </div>
        <div class="messageItem">
          <img src="../../assets/images/foot_messageItem01.png" alt="" />
          <span style="font-size: 0.175rem;">深圳市龙岗区园山街道大运软件小镇39栋3楼
          </span>
        </div>
        <div class="messageItem">
          <img src="../../assets/images/foot_messageItem02.png" alt="" />
          <a style="font-size: 0.175rem;">www.szcshl.com</a>
        </div>
        <!-- <div class="messageItem">
          <img src="../../assets/images/foot_messageItem03.png" alt="" />
          <a class="fs-12">transmi@szcshl.com</a>
        </div> -->

      </div>
      <div style="width: 2rem ;height: 1.5rem;transform: translateX(-1.475rem) translateY(.575rem); font-size: .175rem;">
        <p style="font-size: 0.225rem;text-align: center;margin-bottom: .025rem;">在线咨询</p>
        <img style="width: 2rem ;height: 2rem;" src="~@/assets/newImages/OfficialAccountsQR.jpg" alt="" />
        <p style="font-size: .175rem;text-align: center;">服务时间 9:00 - 18:00</p>
      </div>
      <div class="right">
        <div class="list">
          <div class="item">
            <router-link to="/About/AboutUs" v-scroll-to="'.homeImg'">
              <div class="title">关于我们</div>
            </router-link>
            <router-link to="/About/AboutUs" v-scroll-to="'.AboutUs'">
              <div class="item2">公司简介</div>
            </router-link>
            <router-link to="/About/Advantage" v-scroll-to="'.coreCompetence'">
              <div class="item2">核心能力</div>
            </router-link>
            <router-link to="/About/JoinUs" v-scroll-to="'.developmentHistory'">
              <div class="item2">发展历程</div>
            </router-link>
            <router-link to="/About/JoinUs" v-scroll-to="'.honor'">
              <div class="item2">荣誉资质</div>
            </router-link>
            <router-link to="/About/JoinUs" v-scroll-to="'.patent'">
              <div class="item2">专利软著</div>
            </router-link>
            <router-link to="/About/JoinUs" v-scroll-to="'.partner'">
              <div class="item2">合作伙伴</div>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/ProductPlan/Government" v-scroll-to="'.homeImg'">
              <div class="title">产品方案</div>
            </router-link>

            <router-link to="/ProductPlan/Government" v-scroll-to="'.homeImg'">
              <div class="item2">智慧政务</div>
            </router-link>

            <router-link to="/ProductPlan/Emergency" v-scroll-to="'.homeImg'">
              <div class="item2">智慧应急</div>
            </router-link>
            <router-link to="/ProductPlan/Park" v-scroll-to="'.homeImg'">
              <div class="item2">智慧园区</div>
            </router-link><router-link to="/ProductPlan/Smart" v-scroll-to="'.homeImg'">
              <div class="item2">智能化工程</div>
            </router-link>
            <!-- <router-link to="/ProductPlan/EntDigitalService">
              <div class="item2">企业数字化</div>
            </router-link> -->
          </div>
          <div class="item">
            <router-link to="/CustomerService/custMainContent">
              <div class="title">客户服务</div>
            </router-link>
            <router-link to="/CustomerService/custMainContent" v-scroll-to="'#serial1'">
              <div class="item2">设计咨询</div>
            </router-link>
            <router-link to="/CustomerService/custMainContent" v-scroll-to="'#serial2'">
              <div class="item2">系统开发</div>
            </router-link>
            <router-link to="/CustomerService/custMainContent" v-scroll-to="'#serial3'">
              <div class="item2">集成项目</div>
            </router-link>
            <router-link to="/CustomerService/custMainContent" v-scroll-to="'#serial4'">
              <div class="item2">后期运维</div>
            </router-link>
          </div>
          <!-- <div class="item">
            <router-link to="/NewsTrends/newsMainContent">
              <div class="title">新闻动态</div>
            </router-link>
            <router-link
              to="/NewsTrends/newsMainContent"
              v-scroll-to="'#mainContent'"
            >
              <div class="item2">公司新闻</div>
            </router-link>
            <router-link
              to="/NewsTrends/newsMainContent"
              v-scroll-to="'#mainContent'"
            >
              <div class="item2">行业新闻</div>
            </router-link>
          </div> -->
          <div class="item">
            <router-link to="/ContactUs/conMainContent">
              <div class="title">联系我们</div>
            </router-link>
            <router-link to="/ContactUs/conMainContent" v-scroll-to="'.contactUs'">
              <div class="item2">联系方式</div>
            </router-link>
            <router-link to="/ContactUs/conMainContent" v-scroll-to="'.address'">
              <div class="item2">公司地址</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="bot">
      <!-- <div>
        <img src="@/assets/images/bot.png" alt="" />
      </div> -->
      <div class="fs-12">
        版权所有©2023深圳市创势互联科技有限公司 粤ICP备17164231号-1
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
@import '../../assets/styles/mixins.styl';

a {
  color: #fff !important;
}

.foot {
  box-config(100%, 4.3125rem, '../../assets/images/090.jpeg');
  margin-left: -0.0125rem;
  overflow: hidden;
  color: #ffffff;
  font-family: '微软雅黑';

  .con {
    display: flex;
    margin: 0.25rem;
    margin-left: 1.125rem;
    width: 88%;
  }

  .left {
    width: 50%;
    padding-left: 10%;
    padding-top: 0.5625rem;
    box-sizing: border-box;

    @media screen and (max-width: 17.5rem) {
      font-size: 0.2rem;
    }

    .phone {
      font-size: 0.3rem;
      // background-color: #1e88fb;
      width: 5rem;
      padding: 0.0625rem;
    }

    .messageItem {
      display: flex;
      margin-top: 0.125rem;
      line-height: 0.225rem;
      margin-left: 0.05rem;

      span {
        white-space: nowrap;
      }

      img {
        margin-right: 0.125rem;
      }

      a {
        color: white;
      }
    }
  }

  .right {
    width: 50%;
    padding-left: 5%;
    padding-top: 0.5625rem;
    box-sizing: border-box;

    .list {
      display: flex;
      justify-content: flex-end;

      .item {
        width: 25%;

        .title {
          font-size: 0.225rem;
        }

        .item2 {
          position: relative
          margin-top: 0.125rem;
          font-size: 0.175rem;
          font-weight: lighter;
          transition:.3s;
          &::before{
            position: absolute;
            content:'';
            right: 20px;
            top: 10px;
            width:1px;
            height:10px;
            background: #fff;
            opacity:0;
            transition:.3s ;
          }
          &::after{
            position: absolute;
            content:'';
            width: 5px
            height: 5px;
            right: 17px;
            top: 10px
            border-radius: 1px;
            border:1px solid transparent;
            border-left-color:#fff;
            border-top-color:#fff;
            transform:rotate(45deg);
            opacity:0;
            transition:.3s;
          }
          &:hover{
            font-weight:bolder;
            color: #fff;
            &::after,
            &::before{
              top:0;
              opacity:1
            }
          }
        }
      }
    }
  }

  .bot {
    display: flex;
    width: 100%;
    margin-top: 0.4625rem;
    justify-content: center;
    align-content: center;
  }
}
</style>
