<template>
    <div>
        <div class="title">企业数字化</div>
        <keep-alive>
            <router-view></router-view>
        </keep-alive>

    </div>
</template>
 
<script>
export default {

}
</script>
 
<style lang="less" scoped>
.title {
    font-size: .375rem;
    font-weight: bold;
    color: #04468D;
}
</style>