<template>
  <div class="right">
    <div class="framework">
      <div class="bigTitle">公司架构</div>
      <div class="frameworkList">
        <div class="frameworkBg">
          <div class="frameworkItem">创势集团</div>
          <div class="frameworkItem">深圳团队</div>
          <div class="frameworkItem">南宁团队</div>
        </div>
        <div class="UList">
          <div class="UItem">
            <div class="UListItem">业务销售部</div>
            <div class="UListItem">项目销售工作</div>
            <div class="UListItem">项目市场工作</div>
          </div>
          <div class="UItem">
            <div class="UListItem">项目事业部</div>
            <div class="UListItem">项目销售工作</div>
            <div class="UListItem">项目商务工作</div>
            <div class="UListItem">项目实施工作</div>
            <div class="UListItem">项目售后运维工作</div>
            <div class="UListItem" style="font-size: 0.1875rem">
              建立和维持产品体系工作
            </div>
          </div>
          <div class="UItem">
            <div class="UListItem">研发事业部</div>
            <div class="UListItem">项目实施工作</div>
            <div class="UListItem">项目开放工作</div>
            <div class="UListItem">项目售后运维工作</div>
            <div class="UListItem">方案技术支持</div>
            <div class="UListItem">技术管理工作</div>
          </div>
          <div class="UItem">
            <div class="UListItem">产品设计部</div>
            <div class="UListItem">产品设计及优化工作</div>
            <div class="UListItem">指定产品规范系统工作</div>
            <div class="UListItem">产品案例方案整合工作</div>
          </div>
          <div class="UItem">
            <div class="UListItem">行政人事部</div>
            <div class="UListItem">行政人事事物工作</div>
            <div class="UListItem">财务工作</div>
            <div class="UListItem">公司资质申报工作</div>
            <div class="UListItem">商务接待工作</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
@import '../../assets/styles/mixins.styl';

.active {
  border-right: 0.0375rem solid #04468D;
  color: #04468D !important;
}

.top {
  box-config(100%, 5.625rem, '../../assets/images/aboutTop.png');
  line-height: 5.625rem;

  .aboutP {
    color: #fff;
    font-size: 0.5rem;
    text-align: center;
  }
}

.con {
  padding: 0.975rem 0;
  position: relative;
  display: flex;
  width: 17.5rem;
  min-width: 15.5rem;

  @media screen and (max-width: 17.5rem) {
    width: 15.5rem;
  }

  min-height: 11.25rem;
  margin: 0 auto;
  margin-top: -0.625rem;
  background-color: white;
  border-radius: 0.1rem;
}

.left {
  padding: 0 1rem;

  .leftList {
    border-right: 0.0125rem solid #EEEEEE;
    margin-right: -0.0187rem;
  }

  .left_item {
    height: 0.45rem;
    line-height: 0.45rem;
    font-size: 0.2rem;
    margin-bottom: 0.375rem;
    width: 1.8rem;

    span {
      cursor: pointer;
    }
  }
}

.right {
  width: 12.25rem;
  min-width: 11.125rem;

  @media screen and (max-width: 17.5rem) {
    width: 11.125rem;
  }

  .advantage {
    .managementItem {
      display: flex;
      flex-direction: column;
      justify-content: inherit;
      background-color: #F8F8FA;
      // img
      // margin-bottom -0.125rem
      padding: 0;

      .youshi {
        height: auto;
        padding: 0.125rem;
      }
    }
  }

  .bigTitle {
    font-size: 0.375rem;
    font-weight: bold;
    color: #04468D;
  }

  .introduction {
    p {
      margin: 0.3125rem 0;
    }

    img {
      width: 100%;
    }
  }

  .framework {
    height: 9.375rem;
  }

  .frameworkList {
    padding-top: 1rem;
    height: 3.125rem;
    min-width: 11.5rem;
  }

  .frameworkBg {
    margin: 0 auto;
    position: relative;
    box-config(9.375rem, 2.2375rem, '../../assets/images/framework.png');

    .frameworkItem {
      position: absolute;
      height: 0.5rem;
      line-height: 0.5rem;
      text-align: center;
      width: 2rem;
      background-color: #707070;
      color: #fff;
      font-size: 0.225rem;
      border-radius: 0.0625rem;

      &:nth-child(1) {
        background-color: #FF4141;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &:nth-child(2) {
        background-color: #F29200;
        top: 0.6rem;
        left: 1.325rem;
      }

      &:nth-child(3) {
        background-color: #F29200;
        top: 0.6rem;
        left: 6.0875rem;
      }
    }
  }

  .UList {
    display: flex;
    justify-content: space-between;
    width: 11.5rem;
    margin: 0 auto;

    .UItem {
      &:nth-child(1) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#0072EE, #0072EE);
      }

      &:nth-child(2) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#0072EE, #0072EE);
      }

      &:nth-child(3) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#0072EE, #0072EE);
      }

      &:nth-child(4) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#0072EE, #0072EE);
      }

      &:nth-child(5) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#0072EE, #0072EE);
      }

      .UListItem {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        width: 2.125rem;
        font-size: 0.2rem;
        border-radius: 0.0625rem;
        margin-bottom: 0.4375rem;
        box-shadow: 0rem 0.0375rem 0rem 0.0125rem #eeeeee;
      }
    }
  }
}

.management {
  img {
    width: 100%;
    margin-top: 0.375rem;
  }
}

.managementList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .manTitle {
    font-size: 0.25rem;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      bottom: -0.125rem;
      left: 0rem;
      width: 0.4625rem;
      height: 0.0625rem;
      background-color: #5F9EE2;
    }
  }

  .managementItem {
    border: 0.0125rem solid #EEEEEE;
    border-radius: 0.0625rem;
    padding: 0.1875rem 0.125rem;
    margin-top: 0.75rem;
    width: 46%;
    line-height: 170%;

    img {
      width: 100%;
    }

    p {
      margin-top: 0.25rem;
    }
  }
}

.strength {
  .strengthList {
    display: flex;
    justify-content: space-around;
    margin-top: 0.625rem;

    .strengthItem {
      display: flex;

      img {
        width: 100%;
      }

      .strChild {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 0.175rem;
          height: 0.175rem;
          background-color: #C2DFFF;
          top: -0.0875rem;
          left: -0.0875rem;
        }
      }

      .strChildTitle {
        width: 1.625rem;
        font-size: 0.25rem;
        text-align: center;
        height: 0.5rem;
        line-height: 0.5rem;
        border: 0.0125rem solid #C4C4C4;
        margin-bottom: 0.625rem;
        position: relative;
        background-color: white;
        z-index: 1000;
      }
    }
  }
}

.join {
  .joinActive {
    color: #04468D;
    border-bottom: 0.0375rem solid #04468D;
  }

  img {
    width: 100%;
    height: auto;
    margin-top: 0.5rem;
  }

  .nav {
    display: flex;
    margin-top: 0.525rem;
    margin-bottom: 0.525rem;

    div {
      font-size: 0.25rem;
      height: 0.5rem;
      margin-right: 0.8rem;
      cursor: pointer;
    }
  }

  p {
    line-height: 185%;
  }

  .joinItemTitle {
    font-size: 0.225rem;
    margin-top: 0.35rem;
  }
}

.contact {
  img {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.3125rem;
  }

  .contactList {
    margin: 1rem 0;
    display: flex;
    justify-content: space-around;

    img {
      width: auto;
      display: block;
      margin: 0 auto;
      margin-bottom: 0.3125rem;
    }
  }
}
</style>