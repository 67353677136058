<template>
  <div>
    <!-- <topContent></topContent> -->
    <!-- <topSwiper></topSwiper> -->
    <midContent></midContent>
  </div>
</template>

<script>
// import topContent from './topContent.vue'
import midContent from './midContent.vue'
export default {
  components:{
    // topContent,
    midContent,
  }
}
</script>

<style lang='stylus' scoped>
</style>