<template>
  <div class="advantage">
    <div class="bigTitle">企业优势</div>
    <div class="managementList">
      <div class="managementItem">
        <img src="@/assets/images/advantage01.png" alt="" />
        <div class="youshi">
          <div class="manTitle" style="color:#333333 ;">经验优势</div>
          <p class="fs-14">
            创势互联具备十多年的电子政务相关经验，具备丰富的政务知识，熟悉政府信息化工作流程和管理办法。拥有多个行业的解决方案和自主知识产权的成熟产品，并实施了大量解决方案和重点产品的案例。
          </p>
        </div>
      </div>
      <div class="managementItem">
        <div class="youshi">
          <div class="manTitle" style="color:#333333 ;">业务优势</div>
          <p class="fs-14">
            我公司是智慧城市专业化软件公司，从项目的立项审批、初步设计到过程实施，以及最后的决算审计都全程参与，有着丰富的项目经验和业务知识储备、流程管控经验。
          </p>
        </div>
        <img src="@/assets/images/advantage02.png" alt="" />
      </div>
      <div class="managementItem">
        <img src="@/assets/images/advantage03.png" alt="" />
        <div class="youshi">
          <div class="manTitle" style="color:#333333 ;">团队优势</div>
          <p class="fs-14">
            创势以项目的质量为目标，服务为团队的核心能力，团队的骨干成员（10多名）具有近十年的项目实施经验，同时拥有深圳与南宁两个团队。
          </p>
        </div>
      </div>
      <div class="managementItem">
        <div class="youshi">
          <div class="manTitle" style="color:#333333 ;">服务优势</div>
          <p class="fs-14">
            创势承接政府部门建设信息化系统，做过大量数据对接，同时根据项目情况提供驻点服务，便于对接系统的沟通与技术对接，同时我司在其他案例大部分已经按信息化管理办法与区政务OA、区移动办公平台、区时空平台等。
          </p>
        </div>
        <img src="@/assets/images/advantage04.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
@import '../../assets/styles/mixins.styl';

.right {
  width: 12.25rem;
  min-width: 11.125rem;

  @media screen and (max-width: 17.5rem) {
    width: 11.125rem;
  }

  .advantage {
    .managementItem {
      display: flex;
      flex-direction: column;
      justify-content: inherit;
      background-color: #F8F8FA;
      // img
      // margin-bottom -0.125rem
      padding: 0;

      .youshi {
        height: auto;
        padding: 0.125rem;
      }
    }
  }

  .bigTitle {
    font-size: 0.375rem;
    font-weight: bold;
    color: #04468D;
  }

  .introduction {
    p {
      margin: 0.3125rem 0;
    }

    img {
      width: 100%;
    }
  }

  .framework {
    height: 9.375rem;
  }

  .frameworkList {
    padding-top: 1rem;
    height: 3.125rem;
    min-width: 11.5rem;
  }

  .frameworkBg {
    margin: 0 auto;
    position: relative;
    box-config(9.375rem, 2.2375rem, '../../assets/images/framework.png');
  }
}

.managementList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .manTitle {
    font-size: 0.25rem;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      bottom: -0.125rem;
      left: 0rem;
      width: 0.4625rem;
      height: 0.0625rem;
      background-color: #5F9EE2;
    }
  }

  .managementItem {
    border: 0.0125rem solid #EEEEEE;
    border-radius: 0.0625rem;
    padding: 0.1875rem 0.125rem;
    margin-top: 0.75rem;
    width: 46%;
    line-height: 170%;

    img {
      width: 100%;
    }

    p {
      margin-top: 0.25rem;
    }
  }
}

.strength {
  .strengthList {
    display: flex;
    justify-content: space-around;
    margin-top: 0.625rem;

    .strengthItem {
      display: flex;

      img {
        width: 100%;
      }

      .strChild {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 0.175rem;
          height: 0.175rem;
          background-color: #C2DFFF;
          top: -0.0875rem;
          left: -0.0875rem;
        }
      }

      .strChildTitle {
        width: 1.625rem;
        font-size: 0.25rem;
        text-align: center;
        height: 0.5rem;
        line-height: 0.5rem;
        border: 0.0125rem solid #C4C4C4;
        margin-bottom: 0.625rem;
        position: relative;
        background-color: white;
        z-index: 1000;
      }
    }
  }
}

.join {
  .joinActive {
    color: #04468D;
    border-bottom: 0.0375rem solid #04468D;
  }

  img {
    width: 100%;
    height: auto;
    margin-top: 0.5rem;
  }

  .nav {
    display: flex;
    margin-top: 0.525rem;
    margin-bottom: 0.525rem;

    div {
      font-size: 0.25rem;
      height: 0.5rem;
      margin-right: 0.8rem;
      cursor: pointer;
    }
  }

  p {
    line-height: 185%;
  }

  .joinItemTitle {
    font-size: 0.225rem;
    margin-top: 0.35rem;
  }
}

.contact {
  img {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.3125rem;
  }

  .contactList {
    margin: 1rem 0;
    display: flex;
    justify-content: space-around;

    img {
      width: auto;
      display: block;
      margin: 0 auto;
      margin-bottom: 0.3125rem;
    }
  }
}
</style>