<template>
    <div id="EntMainContent">
        <div class="firBox">
            <div class="tTitle">
                企业价值体系重构<br />
                以新型能力建设为核心路径，基于数据的驱动提高企业资源的综合配置效率
            </div>
            <div class="textPart">
                数字化的核心内涵是数据是企业发展和运营的核心，业务逻辑是将物理世界全面映射到数字世界，利用数字世界沟通、
                信息共享、诊断、预测、模拟等固有优势，与物理世界形成交互和反馈，推进企业发展和运营；主要集成范围是企业级
                集成、产业链集成和产业链生态集成；对技术的要求有云计算、大数据、物联网、移动互联网等新一代信息技术广泛应
                用；主要载体范围有业务信息系统、数据湖和中台等。
            </div>
            <div class="table">
                <div class="tLine">
                    <div class="rowBox">信息化</div>
                    <div class="rowBox">信息化</div>
                    <div class="rowBox">数字化</div>
                </div>
                <div class="tLine">
                    <div class="colBox">核心内涵</div>
                    <div class="rowWhiteBox">
                        线下业务线上化
                    </div>
                    <div class="rowWhiteBox">
                        数据是企业发展和运营的核心
                    </div>
                    <div class="rowWhiteBox">
                        AI进行决策
                    </div>
                </div>
                <div class="tLine">
                    <div class="colBox">核心内涵</div>
                    <div class="rowWhiteBox">
                        优化现有业务流程，实现业务数据化，提高信息
                        共享和工作效率，优化资源配置，支撑分析决策
                    </div>
                    <div class="rowWhiteBox">
                        将物理世界映射到数字世界，利用数字世界沟通、
                        信息共享、诊断、预测、模拟等固有优势，与物
                        理世界形成交互和反馈，推进企业发展和运营。
                    </div>
                    <div class="rowWhiteBox">
                        某些业务场景，利用人工智能技术、物联网技术，
                        基于云计算、大数据、算法让机器进行决策，解放
                        繁重的脑力劳动。
                    </div>
                </div>
                <div class="tLine">
                    <div class="colBox">集成范围</div>
                    <div class="rowWhiteBox">
                        专业集成、企业级集成
                    </div>
                    <div class="rowWhiteBox" style="width:57%;">
                        企业级集成、产业链集成、产业生态集成
                    </div>
                </div>
                <div class="tLine">
                    <div class="colBox">技术要求</div>
                    <div class="rowWhiteBox">
                        数据库、通信、网络技术
                    </div>
                    <div class="rowWhiteBox">
                        云计算、大数据、物联网、移动互联网等新一代信息技术
                    </div>
                    <div class="rowWhiteBox">
                        人工智能AI技术
                    </div>
                </div>
                <div class="tLine">
                    <div class="colBox">主要载体</div>
                    <div class="rowWhiteBox">
                        业务信息系统、数据仓库
                    </div>
                    <div class="rowWhiteBox" style="width:57%;">
                        业务信息系统、数据中台、大数据汇聚、数据分析、数据模型、训练数据等
                    </div>
                </div>
            </div>
        </div>
        <div class="secBox">
            <div class="leftBox">
                <div class="itemBox">
                    <div class="num">1</div>
                    <div class="verticalLine"></div>
                    <div class="textPart">
                        <div class="bTitle">识别数字化核心</div>
                        <div class="conText">
                            形成数字化思维，掌握挖掘数字资产的核心技术，
                            从数字化特征的角度去审视并重构企业的所有流程
                        </div>
                    </div>
                </div>
                <div class="itemBox">
                    <div class="num">2</div>
                    <div class="verticalLine"></div>
                    <div class="textPart">
                        <div class="bTitle">制定数字化战略</div>
                        <div class="conText">
                            基于业务流程梳理进行全面的数字化部署，针对明
                            确的需求和痛点，利用数字技术解决问题
                        </div>
                    </div>
                </div>
                <div class="itemBox">
                    <div class="num">3</div>
                    <div class="verticalLine"></div>
                    <div class="textPart">
                        <div class="bTitle">数字化切入点</div>
                        <div class="conText">
                            切入点的选择是企业的发展痛点、业务潜在价值点，
                            也可以是企业的优势点
                        </div>
                    </div>
                </div>
                <div class="itemBox">
                    <div class="num">4</div>
                    <div class="verticalLine"></div>
                    <div class="textPart">
                        <div class="bTitle">现代化企业架构</div>
                        <div class="conText">
                            从整体/宏观角度描述企业业务、信息系统、技术、
                            数据各方面工作所需的信息,有效地协调企业信息资产
                        </div>
                    </div>
                </div>
            </div>
            <div class="rightBox">
                <div class="topBox"><img src="@/assets/newImages/EntImg1.png" alt=""></div>
                <div class="btmBox">
                    <div class="titleBar">数字化转型宗旨</div>
                    <div class="textPart">
                        <div class="textItem">
                            <span class="tLeft">1.提升企业内部效率:</span>
                            <span class="tRight">运营效率、管理效率、决策效率</span>
                        </div>
                    </div>
                    <div class="textPart">
                        <div class="textItem">
                            <span class="tLeft">2.改善行业效率:</span>
                            <span class="tRight">行业平台建构、细分领域平台构建、资源整合型平台构建</span>
                        </div>
                    </div>
                    <div class="textPart">
                        <div class="textItem">
                            <span class="tLeft">3.构建/颠覆生态:</span>
                            <span class="tRight">资源整合、业务/品牌延伸、数字服务共享</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="thirdBox">
            <div class="boxList">
                <div class="itemBox">
                    <div class="titlePart">工业物联网平台</div>
                    <div class="textPart">
                        将具有感知、监控能力的各类采集、控制传感器及移动通信、智能分析等技术融入
                        到工业生产过程中各环节，从而大幅提高制效率，改善产品质量，降低产品成本和
                        资源消耗，实现将传统工业提升到智能化新阶段
                    </div>
                </div>
                <div class="itemBox"><img src="@/assets/newImages/EntImg2.png" alt=""></div>
                <div class="itemBox">
                    <div class="titlePart">仓储物流一体化系统</div>
                    <div class="textPart">
                        仓储物流管理自动化、一体化，提高仓储物流工作效率，实时仓储物流数据智能分析，
                        物流配送多模式，实现自动物流进度追踪，实现采购、销售、库存、资金全链路管理，
                        数据赋能，助力企业降本增效
                    </div>
                </div>
                <div class="itemBox"><img src="@/assets/newImages/EntImg3.png" alt=""></div>
                <div class="itemBox"><img src="@/assets/newImages/EntImg4.png" alt=""></div>
                <div class="itemBox">
                    <div class="titlePart">质检过程控制平台</div>
                    <div class="textPart">
                        通过标准化的质检和不合格处置流程，采集流程中的过程数据，系统自动对过程数
                        据进行分析汇总，最终形成所需要的统计报表，使得质检过程透明化、可控化
                    </div>
                </div>
                <div class="itemBox"><img src="@/assets/newImages/EntImg5.png" alt=""></div>
                <div class="itemBox">
                    <div class="titlePart">气体泄漏探测系统</div>
                    <div class="textPart">
                        采用红外气体传感器、光致电离气体传感器、气体探测器、气体报警控制及GDS系统、
                        气体分析设备、VOCs在线监测等完成工业环境气体探测物联网系统
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="fourthBox">
            <div class="leftBox">
                <div class="itemBox textIndent">
                    创势互联分布式的物联网(IOT)平台,用于快速实现工业物联网项目和管理物联
                    设备,是可提供PAAS的物联系统网关平台。
                </div>
                <div class="itemBox">
                    <span>驱动层：</span>
                    用于提供标准或者私有协议连接物理设备的物联网通信协议，负责设
                    备的数据采集和指令控制;
                </div>
                <div class="itemBox">
                    <span>数据层：</span>
                    负责设备数据的收集和入库，并提供数据管理接口服务;
                </div>
                <div class="itemBox">
                    <span>管理层：</span>
                    用于提供微服务注册中心、设备指令接口、设备注册与关联配对、
                    数据管理中心，是所有微服务交互的核心部分，负责各类配置数据的管理，
                    并对外提供接口服务;
                </div>
                <div class="itemBox">
                    <span>应用层：</span>
                    用于提供数据开放、任务调度、报警与消息通知、日志管理等，
                    具备对接业务处理平台能力。
                </div>
            </div>
            <div class="rightBox">
                <div class="titleBar">
                    创势物联网平台架构
                </div>
                <div class="imgBar">
                    <img src="@/assets/newImages/EntImg6.png" alt="">
                </div>
            </div>
        </div> -->
    </div>
</template> 
 
<script>
export default {
    name: 'EntMainContent',

}
</script>
 
<style lang="less" scoped>
#EntMainContent {
    .firBox {
        margin: .25rem 0 .625rem 0;

        .tTitle {
            color: #18498b;
            text-align: center;
            font-weight: bold;
            font-size: .25rem;
            margin-bottom: .25rem;
            line-height: .375rem;
        }

        .textPart {
            color: #000000;
            text-indent: .5rem;
            font-weight: bold;
            font-size: .225rem;
            line-height: .375rem;
            margin-bottom: .25rem;
        }

        .table {
            .tLine {
                display: flex;
                justify-content: flex-end;

                .colBox,
                .rowWhiteBox,

                .rowBox {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: .0625rem .125rem;
                    box-sizing: border-box;
                    color: white;
                    text-align: center;
                    background-color: #0070c0;
                    width: 28%;
                    margin: .0625rem;
                    min-height: .5rem;
                }

                .colBox {
                    width: 12%;
                }

                .rowWhiteBox {
                    background-color: white;
                    color: black;
                    border: .0125rem solid black;
                }


            }
        }
    }

    .secBox {
        display: flex;
        justify-content: space-between;
        height: 6.5rem;
        background-color: white;
        margin-bottom: .25rem;

        .leftBox {
            width: 45%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .itemBox {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: .125rem 0;

                .num {
                    color: #18498b;
                    font-size: .5rem;
                    font-family: '华文细黑';
                }

                .verticalLine {
                    margin: 0 .125rem;
                    height: .75rem;
                    border: .0375rem solid #18498b;
                    border-radius: .125rem;
                }

                .textPart {
                    .bTitle {
                        font-weight: bold;
                        color: black;
                        font-size: .225rem;
                    }

                    .conText {
                        color: black;
                        font-size: .2rem;
                    }
                }
            }
        }

        .rightBox {
            width: 51%;

            .topBox {
                height: 3.75rem;
                width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .btmBox {
                height: 40%;
                background-color: #f2f2f2;

                .titleBar {
                    color: #18498b;
                    font-weight: bold;
                    margin: .0625rem 0 .25rem 0;
                    text-align: center;
                    font-size: .225rem;
                }

                .textPart {
                    padding: 0 .25rem;

                    .textItem {
                        margin: .1875rem 0;

                        .tLeft {
                            color: black;
                            font-weight: bold;
                            font-size: .2rem;
                        }

                        .tRight {
                            color: black;
                            font-size: .2rem;
                        }
                    }
                }
            }
        }
    }

    .thirdBox {
        margin-bottom: .25rem;

        .boxList {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            .itemBox {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                width: 2.96rem;
                height: 2.375rem;
                margin: 0 0 .0625rem .075rem;
                background-color: #18498b;
                padding: .025rem;
                box-sizing: border-box;

                img {
                    width: 2.96rem;
                    height: 2.375rem;
                }

                .titlePart {
                    text-align: center;
                    color: white;
                    font-weight: bold;
                    font-size: .2rem;
                }

                .textPart {
                    text-align: center;
                    color: white;
                    font-size: .1625rem;
                }
            }

        }

    }

    .fourthBox {
        display: flex;
        margin-bottom: .625rem;

        .leftBox {
            width: 40%;

            span {
                font-weight: bold;
            }

            .itemBox {
                color: black;
                font-size: .225rem;
                padding: .1875rem .25rem;
                box-sizing: border-box;
            }

            .textIndent {
                text-indent: .4rem;
            }

        }

        .rightBox {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .titleBar {
                height: 1rem;
                line-height: 1rem;
                text-align: center;
                color: black;
                font-weight: bold;
                font-size: .225rem;
            }

            .imgBar {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
</style>