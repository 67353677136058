<template>
    <div class="detailsInfo">
        <div class="container">
            <div class="topCard">
                <!-- <p class="caseShow">案例展示</p> -->
                <router-link to="/" v-scroll-to="'.case'" class="black p">案例展示</router-link>
                <span
                style="
                font-weight: bold;
                margin: 0 0.125rem;
                color: #000;
                transform: translateY(-0.025rem);
                "
                >/</span>
                <p class="caseInfo">案例详情</p>
            </div>
            <div class="case_main">
                <div class="title">{{ dataInfo.title }}</div>
                <div class="content">{{ dataInfo.content }}</div>
                <div class="imgList">
                    <img v-for="(el,index) in dataInfo.swiperImgList" :key="el.id" :src="getURL(dataInfo.swiperImgList, index)" alt="">
                </div>
            </div>
        </div>
        <div class="shortcut">
            <div @click="goBack" class="black p">
                <span>返&nbsp;&nbsp;&nbsp;&nbsp;回</span
                ><img src="@/assets/newImages/返回.png" />
            </div>
        </div>
    </div>
</template>
<script>
import caseInfo from "@/assets/data/caseInfo.json";
export default {
    name: "DetailsInfo",
    data() {
        return {
            id: '',
            dataInfo: {},
            caseInfo
        }
    },
    created() {
        this.getData();
        console.log(111111111111111);
    },
    computed: {
        getURL(){
            return function(arr, i){
                return require(`../../assets/newImages/${arr[i]}`);
            }
        },
    },
    methods: {
        goBack(){
            // this.$emit("getDetailStatus", false);
            // this.$router.go(-1); //返回上一页
            localStorage.setItem('toId', 'caseInfo');
            this.$router.push("/#case")
            // document.querySelector('#case').scrollIntoView(true);
        },
        getData(){
            this.id = this.$route.query.id;
            this.dataInfo = this.caseInfo.caseLists.find(item=>{
                return item.id == this.id;
            })            
        },
    },
};
</script>
<style lang="less" scoped>
    .detailsInfo{
        background-color: #f6f6f6;
        width: 100%;
        position: relative;
        .container{
            width: 56%;
            margin: 0 auto;
            .topCard{
                width: 100%;
                height: 0.65rem;
                display: flex;
                align-items: center;
                font-size: 0.185rem;
                .caseShow, .caseInfo{
                    cursor: pointer;
                }
                .caseInfo{
                    color: rgb(41, 121, 182);
                }
            }
            .case_main{
                background-color: #fff;
                width: 100%;
                padding: 0.5625rem;
                box-sizing: border-box;
                margin-bottom: 0.75rem;
                .title{
                    width: 100%;
                    font-size: 0.28rem;
                    font-weight: bold;
                    color: black;
                    text-align: center;
                    margin-bottom: 0.1875rem;
                }
                .content{
                    text-indent: 0.25rem;
                    font-size: 0.2rem;
                    line-height: 0.375rem;
                    color: #777d85;
                    margin-bottom: 0.25rem;
                }
                .imgList{
                    width: 70%;
                    margin: 0 auto;
                    padding: 0 0.025rem 0.0625rem 0;
                    img{
                        display: block;
                        width: 100%;
                    }   
                }
            }
        }

        .shortcut {
            position: fixed;
            top: 6.2rem;
            right: 3.9rem;
            .p {
                cursor: pointer;
                height: 0.525rem;
                border-radius: 0.525rem;
                width: 1.25rem;
                font-size: 0.2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000;
                background-color: #dce1f4;
                img {
                width: 0.375rem;
                height: 0.375rem;
                margin-left: 0.05rem;
                }
            }
            .p:hover {
                background-color: #78b3f7;
            }
            .back {
                background-color: #dce1f4;
            }
            .next {
                margin-top: 0.0375rem;
            }
        }
    }
</style>
