<template>
    <div class="block">
        <el-carousel trigger="click" class="swiper" height="100%">
            <el-carousel-item class="el-carousel-item" v-for="(item,index) in backBg" :key="index">
                <img style="width: 100%; height: 100%" :src="getURL(backBg, index)" alt="">
                <!-- <div class="bannerBg" :style="{ backgroundImage: `url(${getURL(backBg, index)})` }"></div> -->
                <!-- <div class="slogan" :style="{ backgroundImage: `url(${getURL(fontBg, index)})` }"></div>
                <div v-show="index!==backBg.length-1" class="homeImg" :style="{ backgroundImage: `url(${getURL(graphBg, index)})` }"></div> -->
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
  name: 'BaseCshlWebsiteIndex',

  created(){
    console.log(146546,process.env.BASE_URL);
  },
  data() {
    return {
        // 图形
        // graphBg: [
        //     '首页-图形.png',
        //     '关于我们-图形.png',
        //     '产品方案-图形.png',
        //     '客户服务-图形.png',
        //     '客户服务-图形.png'
        // ],
        // 文字
        // fontBg: [
        //     '首页-文字.png',
        //     '关于我们-文字.png',
        //     '产品方案-文字.png',
        //     '客户服务-文字.png',
        //     '联系我们-文字.png',
        // ],
        // 背景
        backBg: [
            'banner-首页2024.jpeg',
            'banner-关于我们2024.jpeg',
            'banner-产品方案2024.jpeg',
            'banner-客户服务2024.jpeg',
            'banner-联系我们2024.jpeg',
        ]
    };
  },
  computed: {
    getURL(){
        return function(arr, i){
            return require(`../../assets/newImages/${arr[i]}`);
        }
    }
  }
};
</script>
<style lang="less" scoped>

.block{
    width: 100%;
    height: 4.475rem;
    margin-top: 0.8625rem;
    position: relative;
    .swiper {
        width: 100%;
        height: 100%;
        // .bannerBg{
        //     background-size: 100% 100%;
        //     background-position: center;
        //     width: 100%;
        //     height: 100%;
        // }
        // .el-carousel-item {
        //     width: 100%;
        //     height: 100%;
        //     background-size: 100% 100%;
        // }
        // .slogan{
        //     position: absolute;
        //     width: 60%;
        //     height: 100%;
        //     transform: translateX(25%) translateY(12%);
        //     background-size: 100% 100%;
        //     z-index: 5;
        // }
        // .homeImg{
        //     position: absolute;
        //     right: 0;
        //     width: 30%;
        //     height: 100%;
        //     transform: translateX(-40%) translateY(6%);
        //     background-size: 100% 100%;
        //     z-index: 5;
        // }
    }
}

</style>