<template>
  <div class="header" :class="{ logoFlag: logoFlag }">
    <div class="headerLeft">
      <div class="logoBlack" :class="{ logoWhite: !logoFlag }"></div>
      <div class="navigation">
        <ul class="nav_list" :class="{ fontcolor: logoFlag }">
          <li>
            <el-dropdown :show-timeout="550">
              <router-link to="/" v-scroll-to="'.homeImg'" class="el-dropdown-link" :class="{ navActive: routeName == 'ContactUs' }">
                首页
              </router-link>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <router-link to="/" v-scroll-to="'#newsMainContent'">
                    <div class="item2">公司动态</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/" v-scroll-to="'.business'">
                    <div class="item2">业务范围</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/" v-scroll-to="'.case'">
                    <div class="item2">案例展示</div>
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li>
            <el-dropdown :show-timeout="550">
              <router-link to="/About" v-scroll-to="'.block'" class="el-dropdown-link" :class="{ navActive: routeName == 'About' }">
                关于我们
              </router-link>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <router-link to="/About/AboutUs" v-scroll-to="'.AboutUs'">
                    <div class="item2">公司简介</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/About/Advantage" v-scroll-to="'.coreCompetence'">
                    <div class="item2">核心能力</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/About/JoinUs" v-scroll-to="'.developmentHistory'">
                    <div class="item2">发展历程</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/About/JoinUs" v-scroll-to="'.honor'">
                    <div class="item2">荣誉资质</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/About/JoinUs" v-scroll-to="'.patent'">
                    <div class="item2">专利软著</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/About/JoinUs" v-scroll-to="'.partner'">
                    <div class="item2">合作伙伴</div>
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li>
            <el-dropdown :show-timeout="550">
              <router-link to="/ProductPlan" v-scroll-to="'.block'" class="el-dropdown-link" :class="{ navActive: routeName == 'ProductPlan' }">
                产品方案
              </router-link>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <router-link to="/ProductPlan/Government" v-scroll-to="'.block'">
                    <div class="item2">智慧政务</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/ProductPlan/Emergency" v-scroll-to="'.block'">
                    <div class="item2">智慧应急</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/ProductPlan/Park" v-scroll-to="'.block'">
                    <div class="item2">智慧园区</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/ProductPlan/Smart" v-scroll-to="'.block'">
                    <div class="item2">智能化工程</div>
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li>
            <el-dropdown :show-timeout="550">
              <router-link to="/CustomerService/custMainContent" v-scroll-to="'.block'" class="el-dropdown-link" :class="{ navActive: routeName == 'CustomerService' }">
                客户服务
              </router-link>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <router-link to="/CustomerService/custMainContent" v-scroll-to="'#serial1'">
                    <div class="item2">设计咨询</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/CustomerService/custMainContent" v-scroll-to="'#serial2'">
                    <div class="item2">系统开发</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/CustomerService/custMainContent" v-scroll-to="'#serial3'">
                    <div class="item2">集成项目</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/CustomerService/custMainContent" v-scroll-to="'#serial4'">
                    <div class="item2">后期运维</div>
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <!-- <li>
            <router-link to="/NewsTrends" :class="{ navActive: routeName == 'NewsTrends' }">新闻动态</router-link>
          </li>-->
          <li>
            <el-dropdown :show-timeout="550">
              <router-link to="/ContactUs" v-scroll-to="'.block'" class="el-dropdown-link" :class="{ navActive: routeName == 'ContactUs' }">
                联系我们
              </router-link>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <router-link to="/ContactUs/conMainContent" v-scroll-to="'.contactUs'">
                    <div class="item2">联系方式</div>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/ContactUs/conMainContent" v-scroll-to="'.address'">
                    <div class="item2">公司地址</div>
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
    </div>
    <div style="background-color: #2d5ea9; width: 20%; height: 100%; display: flex; justify-content: center; align-items: center;">
      <div class="headerRight" :style="logoFlag ? { color: '#ffffff' } : ''">
        <!-- <span>服务热线：</span> -->
        <span>0755-28248402</span>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      logoFlag: true
    };
  },
  computed: {
    routeName() {
      return this.$route.matched[0]?.name;
    },
    lucency() {
      return this.$route.name;
    }
  },
  methods: {
    upHome() {
      this.$router.push("/home");
    }
  },
  watch: {
    // routeName: {
    //   handler(newVal) {
    //     // if (newVal !== 'home') {
    //     //   this.logoFlag = true;
    //     // } else {
    //     //   this.handleScroll()
    //     // }
    //   },
    //   deep: true,
    //   immediate: true,
    // }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="stylus" scoped>
@import '../../assets/styles/mixins.styl';

.logoFlag {
  background-color: #fff;
}

.fontcolor {
  li {
    a {
      color: #000 !important;
    }
  }
}

.header {
  box-sizing: border-box;
  height: 0.8625rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  position: fixed;
  padding-left: 20%;
  padding-right: 20%;
  border-bottom: 0.0125rem solid #d7e2ed;
  z-index: 99;

  .headerLeft {
    height: 0.8625rem;
    display: flex;
    align-items: center;

    .logoBlack {
      float: left;
      box-config(2.45rem, 0.9875rem, '~@/assets/newImages/companyIcon.png');
    }

    .logoWhite {
      float: left;
      box-config(1.75rem, 0.6875rem, '~@/assets/newImages/companyIconWhite.png');
    }

    .navigation {
      padding-left: 0.5rem;
      height: 100%;
      width: 7.5rem;

      .nav_list, .el-dropdown {
        height: 100%;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 0.25rem;
        font-weight: 600;
        font-family: '微软雅黑'

        .navActive {
          border-bottom: 0.0375rem solid;
          border-image: linear-gradient(to right, #0486FF, #12EFFF) 3;
        }

        li {
          height: 100%;
          display: flex;
          align-items: center;
          a {
            height: 0.8625rem;
            line-height: 0.8625rem;
            color: #fff;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .headerRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 0.25rem;
    font-weight: 600;
    font-family: '微软雅黑'
  }
}
</style>
