import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/index";

// 产品方案
import ProductPlan from "../views/ProductPlan/index";
import Government from "../views/ProductPlan/Government/index";
import GovernmentCom from "../views/ProductPlan/Government/components/index";


import Smart from "../views/ProductPlan/Smart/index";
import SmartCom from "../views/ProductPlan/Smart/components/index";

import Wiring from "@/views/ProductPlan/Smart/components/Wiring";
import BAControl from "@/views/ProductPlan/Smart/components/BAControl";
import MachineRoom from "@/views/ProductPlan/Smart/components/MachineRoom";

// 首页案例跳转详情
import DetailsInfo from "@/views/DetailsInfo/index"

import Emergency from "../views/ProductPlan/Emergency/index";
import EmergencyCom from "../views/ProductPlan/Emergency/components/index";

import details from "@/views/ProductPlan/components/details";
import Park from "../views/ProductPlan/Park/index";
import ParkCom from "../views/ProductPlan/Park/components/index";
import EntDigitalService from '@/views/ProductPlan/EntDigitalService'
import EntMainContent from '@/views/ProductPlan/EntDigitalService/EntMainContent'


import About from "../views/About/index";
import AboutUs from "@/views/About/AboutUs";
import Framework from "@/views/About/Framework";
import Idea from "@/views/About/Idea";
import Advantage from "@/views/About/Advantage";
import Strength from "@/views/About/Strength";
import JoinUs from "@/views/About/JoinUs";

//客户服务
import CustomerService from "@/views/CustomerService"
import CustMainContent from "@/views/CustomerService/custMainContent"

//新闻动态
import NewsTrends from "@/views/NewsTrends"
import NewsMainContent from "@/views/NewsTrends/newsMainContent"

//联系我们
import ContactUs from "@/views/ContactUs"
import ConMainContent from "@/views/ContactUs/conMainContent"
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/DetailsInfo", 
    name: "DetailsInfo", 
    component: DetailsInfo,
  },
  {
    path: "/ProductPlan",
    name: "ProductPlan",
    redirect: "/ProductPlan/Government",
    component: ProductPlan,
    children: [
      {
        path: "Government",
        component: Government,
        redirect: "/ProductPlan/Government/GovernmentCom",
        children: [
          {
            path: "GovernmentCom",
            component: GovernmentCom,
          },
          // {
          //   path: "WholeProcess",
          //   component: WholeProcess,
          // },
          // {
          //   path: "Investment",
          //   component: Investment,
          // },
          // {
          //   path: "Office",
          //   component: Office,
          // },
          // {
          //   path: "Support",
          //   component: Support,
          // },
          // {
          //   path: "Maintain",
          //   component: Maintain,
          // },
          // {
          //   path: "LowCarbon",
          //   component: LowCarbon,
          // },
          // {
          //   path: "CityBuilding",
          //   component: CityBuilding,
          // },
          {
            path: 'details/:num',
            component: details,
          }
        ],
      },
      {
        path: "Smart",
        component: Smart,
        redirect: "/ProductPlan/Smart/SmartCom",
        children: [
          {
            path: "SmartCom",
            // redirect: '/ProductPlan/Smart/SmartCom/VideoMonitor',
            component: SmartCom,
          },
          // {
          //   path: "ParkingLog",
          //   component: ParkingLog,
          // },
          {
            path: "Wiring",
            component: Wiring,
          },
          {
            path: "BAControl",
            component: BAControl,
          },
          {
            path: "MachineRoom",
            component: MachineRoom,
          },
        ],
      },
      {
        path: "Emergency",
        component: Emergency,
        redirect: "/ProductPlan/Emergency/EmergencyCom",
        children: [
          {
            path: "EmergencyCom",
            component: EmergencyCom,
          },
          // {
          //   path: "EmManagement",
          //   component: EmManagement,
          // },
          // {
          //   path: "EmFireControl",
          //   component: EmFireControl,
          // },
          // {
          //   path: "EmForest",
          //   component: EmForest,
          // },
          // {
          //   path: "EmDetect",
          //   component: EmDetect,
          // },
          // {
          //   path: "EmService",
          //   component: EmService,
          // },
        ],
      },
      {
        path: "Park",
        component: Park,
        redirect: "/ProductPlan/Park/ParkCom",
        children: [
          {
            path: "ParkCom",
            // redirect: '/ProductPlan/Smart/SmartCom',
            component: ParkCom,
          },
          // {
          //   path: "VideoMonitor",
          //   component: videoMonitor,
          // },
          // {
          //   path: "Alarm",
          //   component: Alarm,
          // },
          // {
          //   path: "Patrol",
          //   component: Patrol,
          // },
          // {
          //   path: "TallBack",
          //   component: TallBack,
          // },
          // {
          //   path: "Verbal",
          //   component: Verbal,
          // },
          // {
          //   path: "FireControl",
          //   component: FireControl,
          // },
          // {
          //   path: "CableTv",
          //   component: CableTv,
          // },
          // {
          //   path: "VideoConference",
          //   component: VideoConference,
          // },
          // {
          //   path: "Information",
          //   component: Information,
          // },
          // {
          //   path: "EntranceGuard",
          //   component: EntranceGuard,
          // },
          // {
          //   path: "PublicBroadcast",
          //   component: PublicBroadcast,
          // },
          // {
          //   path: "Network",
          //   component: Network,
          // },
          // {
          //   path: "ParkingLog",
          //   component: ParkingLog,
          // },
          // {
          //   path: 'details/:num',
          //   component: details,
          // }
        ],
      },
      {
        path: "EntDigitalService",
        component: EntDigitalService,
        redirect: "/ProductPlan/EntDigitalService/EntMainContent",
        children: [
          {
            path: "EntMainContent",
            component: EntMainContent,
          },
        ],
      }
    ],
  },
  {
    path: "/About",
    name: "About",
    component: About,
    redirect: "/About/AboutUs",
    children: [
      {
        path: "/About/AboutUs",
        component: AboutUs,
      },
      {
        path: "/About/Framework",
        component: Framework,
      },
      {
        path: "/About/Idea",
        component: Idea,
      },
      {
        path: "/About/Advantage",
        component: Advantage,
      },
      {
        path: "/About/Strength",
        component: Strength,
      },
      {
        path: "/About/JoinUs",
        component: JoinUs,
      },
    ],
  },
  {
    path: "/CustomerService",
    name: "CustomerService",
    component: CustomerService,
    redirect: "/CustomerService/custMainContent",
    children: [
      {
        path: "custMainContent",
        component: CustMainContent,
      }
    ]
  },
  {
    path: "/NewsTrends",
    name: "NewsTrends",
    component: NewsTrends,
    redirect: "/NewsTrends/newsMainContent",
    children: [
      {
        path: "newsMainContent",
        component: NewsMainContent,
      }
    ]
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: ContactUs,
    redirect: "/ContactUs/conMainContent",
    children: [
      {
        path: "conMainContent",
        component: ConMainContent,
      }
    ]
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // if(to.fullPath == '/' || to.fullPath == '/Government/GovernmentCom'){
    //   return { x: 0, y: 0 }
    // }
    if (
      from.fullPath.slice(1, 12) == "SuccessCase" &&
      from.fullPath != "/SuccessCase/SuccessCaseCom"
    ) {
      return { x: 0, y: 270 };
    }
    if (
      from.fullPath == "/" ||
      to.fullPath == "/" ||
      from.fullPath == "/SuccessCase/SuccessCaseCom" ||
      to.fullPath == "/SuccessCase/SuccessCaseCom" ||
      from.fullPath == "/About" ||
      to.fullPath == "/About"
    ) {
      return { x: 0, y: 0 };
    }
    if (savedPosition) {
      return savedPosition
    }
  },
});

export default router;
