<template>
    <div class="main">
        <!-- <div class="top">
            <div class="slogan"></div>
            <div class="homeImg"></div>
        </div> -->
        <!-- 关于我们 -->
        <div class="AboutUs">
            <div class="abouUsTitle">关于我们</div>
            <div class="introduce">
                <div class="introduceImg">
                    <img class="companyImg" src="@/assets/newImages/gate.jpg" alt="">
                    <div class="companyImg-items">
                        <img class="companyImg-item" src="@/assets/newImages/newsImg-new1.png" alt="">
                        <img class="companyImg-item" src="@/assets/newImages/newsImg-new2.png" alt="">
                        <img class="companyImg-item" src="@/assets/newImages/newsImg-new3.png" alt="">
                    </div>
                </div>
                <div class="introduceText">
                    <div class="text">
                        <p>
                            深圳市创势互联科技有限公司成立于2016年，秉持技术创新精神，运用物联网、云计算、大数据、AI智能、5G等技术为客户提供智慧城市信息化与数字化场景应用解决方案。业务主要涵盖智慧政务、智慧应急、智慧园区、企业数字化4大方面，服务涵盖软件开发的全生命周期与大型集成项目落地实施。
                        </p>
                        <p>
                            创势互联不仅拥有专精特新、国家高新技术企业、双软企业、CMMi
                            3、ISO9001、ISO27001等多项资质，还拥有数个自主商标，多项发明专利，50多个软件著作权，目前已成功为客户提供软硬件综合解决方案50多套。并且与中国电科、华为、大华科技、三大运营商单位等建立了长期战略合作伙伴关系，构建出自身在行业中核心竞争力和生态圈。未来，创势互联将继续秉承“让技术更好地服务社会”的理念，持续为客户提供更专业、更全面的服务。
                        </p>
                    </div>
                    <div class="slogan">
                        <div class="element">
                            <div class="mission">
                                <div>企业使命</div>
                            </div>
                            <span>让技术更好地服务社会</span>
                        </div>
                        <div class="element">
                            <div class="mission">
                                <div>企业作风</div>
                            </div>
                            <span>行动、精进、成效</span>
                        </div>
                        <div class="element">
                            <div class="mission">
                                <div>企业价值观</div>
                            </div>
                            <span>以交付为导向，以奋斗为成本</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="introduceInformation" id="count">
                <div class="data">
                    <div class="item count">
                        <div>
                            <div class="number">
                                <countTo :startVal=0 :endVal=4 :duration=3000></countTo>
                                <span>大</span>
                            </div>
                            <div class="desc">业务板块</div>
                        </div>
                        <div>
                            <div class="number">
                                <countTo :startVal=0 :endVal=10 :duration=3000></countTo>
                                <span>+</span>
                            </div>
                            <div class="desc">发明专利</div>
                        </div>
                        <div>
                            <div class="number">
                                <countTo :startVal=0 :endVal=20 :duration=3000></countTo>
                                <span>+</span>
                            </div>
                            <div class="desc">重点客户</div>
                        </div>
                        <div>

                            <div class="number">
                                <countTo :startVal=0 :endVal=50 :duration=3000></countTo>
                                <span>+</span>
                            </div>
                            <div class="desc">成功案例</div>
                        </div>
                        <div>
                            <div class="number">
                                <countTo :startVal=0 :endVal=50 :duration=3000></countTo>
                                <span data-v="+">+</span>
                            </div>
                            <div class="desc">软件著作权</div>
                        </div>
                    </div>
                    <div class="itemSec"></div>
                </div>
            </div>
        </div>
        <!-- 核心能力 -->
        <div class="coreCompetence">
            <div class="coreCompetenceTitle">核心能力</div>
            <div class="core">
                <div class="core-item">
                    <img src="~@/assets/newImages/AboutUsIcon1.jpg" alt/>
                    <div class="serialNumber">01</div>
                    <div class="title">从0—1定制化设计</div>
                    <p>
                        客户仅需提出建设目标，创势互联便可自行严格按照政务/企业需求，完成从0-1定制系统开发设计，实现一站式全包式服务，让客户省心省力</p>
                </div>
                <div class="core-item">
                    <img src="~@/assets/newImages/AboutUsIcon2.png" alt/>
                    <div class="serialNumber">02</div>
                    <div class="title">高效率交付</div>
                    <p>
                        经过8年发展，目前创势互联已成为快速响应的高专业高素养团队，常规企业交付周期需6-8周，我司仅需2周即可完成交付且保证系统稳定运行</p>
                </div>
                <div class="core-item">
                    <img src="~@/assets/newImages/AboutUsIcon3.png" alt/>
                    <div class="serialNumber">03</div>
                    <div class="title">高资源整合能力</div>
                    <p>与中国三大运营商、中国电科、华为、大华科技等20多家集团公司，建立了深度合作关系并深受其认可</p>
                </div>
                <div class="core-item">
                    <img src="~@/assets/newImages/AboutUsIcon4.png" alt/>
                    <div class="serialNumber">04</div>
                    <div class="title">大型项目落地执行能力</div>
                    <p>具有一支“全能型”集成项目落地队伍，无需资源协调，可自行对项目进行全流程全覆盖包办代办落地执行</p>
                </div>
                <div class="core-item">
                    <img src="~@/assets/newImages/AboutUsIcon5.png" alt/>
                    <div class="serialNumber">05</div>
                    <div class="title">丰富的政府项目经验</div>
                    <p>在长期经营过程中积累了丰富的政府项目经验和区域领先优势，特别是大规模的智慧城市软件实施能力</p>
                </div>
            </div>
        </div>
        <!-- 发展历程 -->
        <div class="developmentHistory">
            <div class="developmentHistoryTitle">发展历程</div>
            <div class="interlinkage">
                <div class="year2016">2016</div>
                <div class="p2016">公司成立，致力于软件与信息服务、网络与通信领域。</div>
                <div class="year2017">2017</div>
                <div class="p2017">完成AI人工智能算法技术实现与智慧园区、文体设施特征建模。</div>
                <div class="year2018">2018</div>
                <div class="p2018">以自然语义识别技术的支撑对各类项目的设计方案进行重复性、有效性的智能判别。</div>
                <div class="year2019">2019</div>
                <div class="p2019">初步形成了智慧政务和智慧园区产品线。</div>
                <div class="year2020">2020</div>
                <div class="p2020">从智慧消防云平台搭建完成开始逐步涉及各类应急业务。</div>
                <div class="year2021">2021</div>
                <div class="p2021">形成智慧应急产品体系。</div>
                <div class="year2022">2022</div>
                <div class="p2022">实现了以感知数据抓取、业务数据汇集、智能数据分析的画像平台。</div>
                <div class="year2023">2023</div>
                <div class="p2023">乔迁新办公区，荣获专精特新企<br>业认证。</div>
                <div class="year2024">2024</div>
                <div class="p2024">开拓多元销售渠道，增<br>强发展活力。</div>
            </div>
        </div>
        <!-- 荣誉资质 -->
        <div class="honor">
            <div class="honorTitle">荣誉资质</div>
            <div class="mySwiper">
                <div class="swiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="item,index in imagePaths" :key="index">
                            <img :src="item.imgPath" alt="" class="img" />
                            <p class="imgTitle">{{item.imgTitle}}</p>
                        </div>
                    </div>
                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </div>
        </div>


        <!-- 专利 -->
        <div class="Softness">
            <div class="SoftnessTitle">案例展示</div>
            <div class="certify">
                <div class="swiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg1.png"/>
                            <p title="外观设计专利证书">外观设计专利证书</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg2.png"/>
                            <p title="政府投资项目全流程管理信息系统软件">政府投资项目全流程管理信息系统软件</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg3.png"/>
                            <p title="文体旅游设施管理系统软件">文体旅游设施管理系统软件</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg4.png"/>
                            <p title="政府投资项目评审专家管理系统">政府投资项目评审专家管理系统</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg5.png"/>
                            <p title="智慧街道综合业务管理系统">智慧街道综合业务管理系统</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg6.png"/>
                            <p title="循环经济网络平台系统软件">循环经济网络平台系统软件</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg7.png"/>
                            <p title="政府项目全流程综合管理平台">政府项目全流程综合管理平台</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg8.png"/>
                            <p title="智慧园区综合服务系统V1.0">智慧园区综合服务系统V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg9.png"/>
                            <p title="基于地图应用的综合服务平台V1.0">基于地图应用的综合服务平台V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg10.png"/>
                            <p title="智慧消防系统V1.0">智慧消防系统V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg11.png"/>
                            <p title="网格化企业微信应用软件V1.0">网格化企业微信应用软件V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg12.png"/>
                            <p title="政府信息化项目全流程PMO管理系统V1.0">政府信息化项目全流程PMO管理系统V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg13.png"/>
                            <p title="重点区域综合信息管理系统软件V1.0">重点区域综合信息管理系统软件V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg14.png"/>
                            <p title="智慧街道数据管理系统V1.0">智慧街道数据管理系统V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg15.png"/>
                            <p title="监狱生产管理系统V1.0">监狱生产管理系统V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg16.png"/>
                            <p title="智慧园区停车管理系统V1.0">智慧园区停车管理系统V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg17.png"/>
                            <p title="循环经济数据监测管理平台V1.0.8">循环经济数据监测管理平台V1.0.8</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg18.png"/>
                            <p title="政务资料管理系统V1.0">政务资料管理系统V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg19.png"/>
                            <p title="综合运营管理平台V1.0">综合运营管理平台V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg20.png"/>
                            <p title="多功能杆管理平台V1.0">多功能杆管理平台V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg21.png"/>
                            <p title="后勤工作事项管理系统V1.0">后勤工作事项管理系统V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg22.png"/>
                            <p title="\森林防火视频管理装置平台V1.0">森林防火视频管理装置平台V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg23.png"/>
                            <p title="综合运营管理平台V1.0">综合运营管理平台V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg24.png"/>
                            <p title="应急物资管理系统V1.0">应急物资管理系统V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg25.png"/>
                            <p title="监测预警专题系统V1.0">监测预警专题系统V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg26.png"/>
                            <p title="森林防火管控平台软件V1.0">森林防火管控平台软件V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg27.png"/>
                            <p title="政务简报编辑系统V1.0">政务简报编辑系统V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg28.png"/>
                            <p title="影像库管理系统V1.0">影像库管理系统V1.0</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg29.png"/>
                            <p title="部门工作画像系统">部门工作画像系统</p>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg30.png"/>
                            <p title="低碳信息管理系统">低碳信息管理系统</p>
                        </div>
                    </div>
                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </div> 
        </div>
        <!-- <div class="patent">
            <div class="patentTitle">专利软著</div>
            <div id="certify">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg1.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg2.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg3.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg4.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg5.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg6.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg7.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg8.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg9.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg10.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg11.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg12.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg13.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg14.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg15.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg16.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg17.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg18.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg19.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg20.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg21.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg22.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg23.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg24.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg25.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg26.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg27.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg28.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg29.png"/>
                        </div>
                        <div class="swiper-slide">
                            <img src="~@/assets/newImages/patentImg30.png"/>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </div>
            <div class="triangle"></div>
            <div class="patentImgTitle">{{ patentTitle }}</div>
        </div> -->
        <!-- 合作伙伴 -->
        <div class="partner">
            <div class="partnerTitle">合作伙伴</div>
            <div class="partners">
                <div class="partnerItem">
                    <img src="~@/assets/newImages/ChinaTelecom.png" alt/>
                </div>
                <div class="partnerItem">
                    <img style="width: 74%; height: 71%" src="~@/assets/newImages/ChinaMobile.png" alt/>
                </div>
                <div class="partnerItem">
                    <img style="width: 64%; height: 81%" src="~@/assets/newImages/ChinaUnicom.png" alt/>
                </div>
                <div class="partnerItem">
                    <img style="width: 72%; height: 47%" src="~@/assets/newImages/pengwei.png" alt/>
                </div>
                <div class="partnerItem">
                    <img style="width: 56%; height: 65%" src="~@/assets/newImages/quanzhi.png" alt/>
                </div>
                <div class="partnerItem">
                    <img src="~@/assets/newImages/海康威视.png" alt/>
                </div>
                <div class="partnerItem">
                    <img style="width: 74%; height: 71%" src="~@/assets/newImages/恩博科技.png" alt/>
                </div>
                <div class="partnerItem">
                    <img style="width: 84%; height: 61%" src="~@/assets/newImages/华鹏飞.png" alt/>
                </div>
                <div class="partnerItem">
                    <img style="width: 84%; height: 65%" src="~@/assets/newImages/神舟数码.png" alt/>
                </div>
                <div class="partnerItem">
                    <img style="width: 54%; height: 91%" src="~@/assets/newImages/电子科技大学.png" alt/>
                </div>
                <div class="partnerItem">
                    <img style="width: 74%; height: 56%" src="~@/assets/newImages/longrice.png" alt/>
                </div>
                <div class="partnerItem">
                    <img style="width: 70%; height: 123%" src="~@/assets/newImages/云飞.png" alt/>
                </div>
                <div class="partnerItem">
                    <img src="~@/assets/newImages/hua.png" alt/>
                </div>
                <div class="partnerItem">
                    <img src="~@/assets/newImages/中科软科技.png" alt/>
                </div>
                <div class="partnerItem">
                    <img src="~@/assets/newImages/casc.png" alt/>
                </div>
                <div class="partnerItem">
                    <img src="~@/assets/newImages/捷顺.png" alt/>
                </div>
                <div class="partnerItem">
                    <img style="width: 54%; height: 48%" src="~@/assets/newImages/42.png" alt/>
                </div>
                <div class="partnerItem">
                    <img src="~@/assets/newImages/中国电科.png" alt/>
                </div>
                <div class="partnerItem">
                    <img style="width: 50%; height: 90%" src="~@/assets/newImages/中国通信服务.png" alt/>
                </div>
                <div class="partnerItem">
                    <img src="~@/assets/newImages/中软.png" alt/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import CountTo from 'vue-count-to'
import {pageMixin} from "@/mixins/index.js";

export default {
    mixins: [pageMixin],
    components: {
        CountTo,
        Swiper
    },
    data() {
        return {
            imagePaths: [
                {'imgPath': require('@/assets/honorImages/3A级信用企业.png')},
                {'imgPath': require('@/assets/honorImages/CMMI3企业认证.png')},
                {'imgPath': require('@/assets/honorImages/高新技术企业认证.png')},
                {'imgPath': require('@/assets/honorImages/软件企业证书.png')},
                {'imgPath': require('@/assets/honorImages/深圳应急管理学会-会员单位.png')},
                {'imgPath': require('@/assets/honorImages/北京大学深圳研究院-产学研合作基地.png')},
                {'imgPath': require('@/assets/honorImages/智慧园区软件产品证书.png')},
                {'imgPath': require('@/assets/honorImages/中小企业发展促进会-会员单位.png')},
                {'imgPath': require('@/assets/honorImages/深圳软件行业协会-会员单位.png')},
                {'imgPath': require('@/assets/honorImages/专精特新中小企业认证.png')},
                {'imgPath': require('@/assets/honorImages/低碳信息管理系统麒麟软件认证.png')},
                {'imgPath': require('@/assets/honorImages/恩博-广东区域行业合作伙伴.png')},
                {'imgPath': require('@/assets/honorImages/龙岗智慧城市产业联盟-会员单位.png')},
                {'imgPath': require('@/assets/honorImages/ISO9001质量认证.png')},
                {'imgPath': require('@/assets/honorImages/ISO27001信息安全管理认证.png')},
                {'imgPath': require('@/assets/honorImages/售后服务认证证书.png')},
                {'imgPath': require('@/assets/honorImages/商标注册证.png')},
                {'imgPath': require('@/assets/honorImages/龙岗高新技术产业会员单位.png')},
                {'imgPath': require('@/assets/honorImages/优秀软件供应商-智慧龙岗.png')},
                {'imgPath': require('@/assets/honorImages/绽放杯-5G应用大赛智慧园区.png')},
                {'imgPath': require('@/assets/honorImages/绽放杯-5G应用大赛.png')},
            ],
            // imagePaths1: [
            //     require('@/assets/newImages/龙岗智慧城市产业联盟-会员单位.png'),
            //     require('@/assets/newImages/软件企业证书.png'),
            //     require('@/assets/newImages/商标注册证.jpg'),
            //     require('@/assets/newImages/深圳软件行业协会-会员单位.png'),
            //     require('@/assets/newImages/深圳应急管理学会-会员单位2021年颁发.png'),
            //     require('@/assets/newImages/深圳应急管理学会-会员单位2022年颁发.png')
            // ],
            // imagePaths2: [
            //     require('@/assets/newImages/售后服务认证证书.png'),
            //     require('@/assets/newImages/信息安全管理体系认证证书.png'),
            //     require('@/assets/newImages/优秀软件供应商-智慧龙岗.png'),
            //     require('@/assets/newImages/绽放杯-5G应用大赛.png'),
            //     require('@/assets/newImages/绽放杯-5G应用大赛智慧园区.png'),
            //     require('@/assets/newImages/智慧园区软件产品证书.png')
            // ],
            // imagePaths3: [
            //     require('@/assets/newImages/中小企业发展促进会-会员单位.png'),
            //     require('@/assets/newImages/专精特新中小企业.png'),
            //     require('@/assets/newImages/北京大学深圳研究院-产学研合作基地.png'),
            //     require('@/assets/newImages/ISO9001.png'),
            //     require('@/assets/newImages/CMMI 3.png'),
            //     require('@/assets/newImages/3A级信用企业.png')
            // ],
            // currentFileName: [],
            // currentFileName1: '',
            // currentFileName2: '',
            // currentFileName3: '',
            patentTitle: "",
            patentInfo: [
                {
                    url: "~@/assets/newImages/patentImg1.png",
                    title: "外观设计专利证书"
                },
                {
                    url: "~@/assets/newImages/patentImg2.png",
                    title: "政府投资项目全流程管理信息系统软件"
                },
                {
                    url: "~@/assets/newImages/patentImg3.png",
                    title: "文体旅游设施管理系统软件"
                },
                {
                    url: "~@/assets/newImages/patentImg4.png",
                    title: "政府投资项目评审专家管理系统"
                },
                {
                    url: "~@/assets/newImages/patentImg5.png",
                    title: "智慧街道综合业务管理系统"
                },
                {
                    url: "~@/assets/newImages/patentImg6.png",
                    title: "循环经济网络平台系统软件"
                },
                {
                    url: "~@/assets/newImages/patentImg7.png",
                    title: "政府项目全流程综合管理平台"
                },
                {
                    url: "~@/assets/newImages/patentImg8.png",
                    title: "智慧园区综合服务系统V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg9.png",
                    title: "基于地图应用的综合服务平台V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg10.png",
                    title: "智慧消防系统V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg11.png",
                    title: "网格化企业微信应用软件V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg12.png",
                    title: "政府信息化项目全流程PMO管理系统V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg13.png",
                    title: "重点区域综合信息管理系统软件V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg14.png",
                    title: "智慧街道数据管理系统V1.0"
                },

                {
                    url: "~@/assets/newImages/patentImg15.png",
                    title: "监狱生产管理系统V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg16.png",
                    title: "智慧园区停车管理系统V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg17.png",
                    title: "循环经济数据监测管理平台V1.0.8"
                },
                {
                    url: "~@/assets/newImages/patentImg18.png",
                    title: "政务资料管理系统V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg19.png",
                    title: "综合运营管理平台V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg20.png",
                    title: "多功能杆管理平台V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg21.png",
                    title: "后勤工作事项管理系统V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg22.png",
                    title: "森林防火视频管理装置平台V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg23.png",
                    title: "综合运营管理平台V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg24.png",
                    title: "应急物资管理系统V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg25.png",
                    title: "监测预警专题系统V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg26.png",
                    title: "森林防火管控平台软件V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg27.png",
                    title: "政务简报编辑系统V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg28.png",
                    title: "影像库管理系统V1.0"
                },
                {
                    url: "~@/assets/newImages/patentImg29.png",
                    title: "部门工作画像系统"
                },
                {
                    url: "~@/assets/newImages/patentImg30.png",
                    title: "低碳信息管理系统"
                }
            ]
        };
    },
    computed: {},
    created(){
        this.updateLiText();
    },
    mounted() {
        const swiper1 = new Swiper(".mySwiper .swiper", {
            slidesPerView: 4,
            spaceBetween: 10,
            navigation: {
                nextEl: '.mySwiper .swiper-button-next',
                prevEl: '.mySwiper .swiper-button-prev',
            },
            loop: true,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false,
                reverseDirection: false,
            }
        });
        const swiper2 = new Swiper(".certify .swiper", {
            slidesPerView: 4,
            spaceBetween: 10,
            loop: true,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false,
                reverseDirection: false,
            },
            navigation: {
                nextEl: ".certify .swiper-button-prev",
                prevEl: ".certify .swiper-button-next"
            },
        })
        this.observerCount()
    },
    methods: {
        updateLiText() {
            this.imagePaths.forEach(item=>{
                let path = item.imgPath.split('/');
                item.imgTitle = path[1].split('.')[0];
            })
            console.log("imgpath", this.imagePaths);
            // const path = this.imagePaths[index].split('/');
            // this.currentFileName = path[1].split('.')[0];
            // this.currentFileName = filePath.split('/').pop().replace(/\.\w+$/, '');
        },
        // updateLiText1(index) {
        //     const path = this.imagePaths[index].split('/');
        //     this.currentFileName1 = path[1].split('.')[0];
        // },
        // updateLiText2(index) {
        //     const path = this.imagePaths[index].split('/');
        //     this.currentFileName2 = path[1].split('.')[0];
        // },
        // updateLiText3(index) {
        //     const path = this.imagePaths[index].split('/');
        //     this.currentFileName3 = path[1].split('.')[0];
        // },
        observerCount() {
            const observer = new IntersectionObserver((entries) => {
                const [entry] = entries
                if (entry.isIntersecting) {
                    console.log(entry)
                    for (const com of this.$children) {
                        if (com.$vnode.componentOptions.tag === 'countTo') {
                            com.start()
                        }
                    }
                }
            }, {threshold: 1})
            observer.observe(document.getElementById('count'))
        }
    },

};

</script>

<style lang="less" scoped>
@import "@/assets/styles/mixins.less";

.count {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.2rem;

    & > div {
        position: relative;
        padding: 0 28px;
        color: #fff;
        text-align: center;

        &:not(:last-child) {
            &::after {
                position: absolute;
                content: '';
                width: 1px;
                top: 10px;
                bottom: 10px;
                right: 0;
                background-color: #fff8;
            }
        }

    }

    .desc {
        font-weight: bolder;
        letter-spacing: 1px;
    }

    .number {

        color: #75ABD3;

        span {
            position: relative;
            -webkit-text-stroke: 1px #fff;
            display: inline-block;
            transform: skewX(-8deg);
            font-weight: bolder;

            &:first-child {
                font-size: 0.45rem;
            }

            &:last-child {
                font-size: 0.3rem;
            }
        }

    }
}

.main {
    background-color: #ffffff;

    .top {
        position: relative;
        // margin-top: .85rem;
        // box-config(100%, 5.625rem, '../../assets/newImages/aboutUsBanner.png');
        // line-height: 5.625rem;
        margin-top: 0rem;
        .box-config(100%, 4.875rem, "../../assets/newImages/关于我们-背景.png");

        // margin-left: -0.025rem;
        .slogan {
            position: absolute;
            width: 60%;
            height: 100%;
            transform: translateX(25%) translateY(12%);
            background-image: url("../../assets/newImages/关于我们-文字.png");
            background-size: 100% 100%;
        }

        .homeImg {
            position: absolute;
            right: 0;
            transform: translateX(-13%) translateY(9%);
            width: 40%;
            height: 100%;
            background: url("../../assets/newImages/关于我们-图形.png") no-repeat;
            background-size: 80% 95%;
        }
    }

    .AboutUs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0.125rem;
        background-color: #fff;

        .abouUsTitle {
            font-size: 0.375rem;
            height: 0.5rem;
            background: linear-gradient(#fff 80%, #9bbbd6 100%);
            margin-top: 0.5rem;
            color: #2d5ea9;
            font-weight: bold;
        }

        .introduce {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 70%;
            margin-top: 0.5rem;
            height: 6.75rem;

            .introduceImg {
                // flex: 1;
                width: 50%;
                height: 100%;
                // background: url("~@/assets/newImages/gate4.jpg") no-repeat left top/100% 100%;
                margin-right: 0.2rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .companyImg{
                    width: 100%;
                    height: 69.9%;
                }
                .companyImg-items{
                    width: 100%;
                    height: 29.8%;
                    display: flex;
                    justify-content: space-between;
                    // align-content: center;
                    .companyImg-item{
                        width: 33.2%;
                        height: 100%;
                    }
                }
            }

            .introduceText {
                width: 48%;
                height: 6.75rem;
                // flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .text {
                    line-height: 0.4rem;
                    // margin-bottom: 10px;

                    p {
                        text-indent: 2em;
                        font-size: 0.2rem;
                        margin-bottom: 0.125rem;
                    }
                }

                .slogan {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-top: 0.25rem;

                    .element {
                        width: 80%;
                        height: 0.375rem;
                        // margin-top: 0.15rem;
                        // background-color: pink;
                        border-bottom: 0.0125rem solid;
                        border-image: linear-gradient(to right, #2b5ea8, #fff) 1;
                        display: flex;
                        
                        &:not(:last-child) {
                            margin-bottom: 0.4rem;
                        }


                        .mission {
                            // float: left;
                            background-color: #2b5ea3;
                            width: 1.5rem;
                            height: 0.375rem;
                            transform: skew(-38deg) translateX(10%);
                            border-radius: 0.1rem 0 0.1rem 0;
                            color: #ffffff;

                            & > div {
                                transform: skew(38deg);
                                // width: 100%;
                                height: 0.375rem;
                                line-height: 0.375rem;
                                text-align: start;
                                padding-left: 0.1875rem;
                                font-size: 0.225rem;
                            }
                        }

                        span {
                            margin-left: 1rem;
                            line-height: 0.375rem;
                            color: #000000;
                            font-size: 0.2rem;
                        }
                    }
                }
            }
        }

        .introduceInformation {
            width: 70%;
            height: 6.25rem;
            background-image: url("../../assets/newImages/AboutUsMidBannerBg.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-top: 0.5rem;
            display: flex;
            justify-content: center;

            .data {
                width: 12.5rem;
                margin-top: 0.5rem;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-evenly;

                .item {
                    width: 100%;
                    height: 1.4375rem;
                    //background-image: url("../../assets/newImages/AboutUsMidBannerTitle.png");
                    //background-repeat: no-repeat;
                    //background-size: 65% 65%;
                    //background-position: center center;
                }

                .itemSec {
                    width: 20%;
                    height: 2.5rem;
                    background-image: url("../../assets/newImages/AboutUsMidBannerFont.png");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
    }

    .coreCompetence {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .coreCompetenceTitle {
            font-size: 0.375rem;
            height: 0.5rem;
            background: linear-gradient(#fff 80%, #9bbbd6 100%);
            margin-top: 0.5rem;
            color: #2d5ea9;
            font-weight: bold;
        }
    }

    .developmentHistory {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .developmentHistoryTitle {
            font-size: 0.375rem;
            height: 0.5rem;
            background: linear-gradient(#fff 80%, #9bbbd6 100%);
            margin-top: 0.5rem;
            color: #2d5ea9;
            font-weight: bold;
        }

        .interlinkage {
            height: 9.25rem;
            width: 100%;
            background-image: url("../../assets/newImages/关于我们-公司历程.png");
            background-size: 100% 85%;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;

            .year2016 {
                position: absolute;
                font-size: 0.2125rem;
                top: 3.74rem;
                left: 2.3rem;
                color: #fff;
            }

            .p2016 {
                position: absolute;
                width: 2.5rem;
                top: 4.1rem;
                left: 2.55rem;
                color: #555c66;
                font-size: 0.2rem;
                line-height: 0.335rem;
            }

            .year2017 {
                position: absolute;
                font-size: 0.2125rem;
                top: 7.53rem;
                left: 4rem;
                color: #fff;
            }

            .p2017 {
                position: absolute;
                top: 6.55rem;
                left: 5.15rem;
                color: #555c66;
                font-size: 0.2rem;
                width: 2rem;
                line-height: 0.335rem;
            }

            .p2018 {
                position: absolute;
                top: 6.2rem;
                left: 9.155rem;
                color: #555c66;
                font-size: 0.2rem;
                width: 2.7rem;
                line-height: 0.335rem;
            }

            .year2018 {
                position: absolute;
                font-size: 0.2125rem;
                top: 7.88rem;
                left: 7.95rem;
                color: #fff;
            }

            .year2019 {
                position: absolute;
                font-size: 0.2125rem;
                top: 2.47rem;
                left: 7.305rem;
                color: #fff;
            }

            .p2019 {
                position: absolute;
                width: 2rem;
                top: 2.85rem;
                left: 7.85rem;
                color: #555c66;
                font-size: 0.2rem;
                line-height: 0.335rem;
            }

            .year2020 {
                position: absolute;
                font-size: 0.2125rem;
                top: 2.065rem;
                left: 10.395rem;
                color: #fff;
            }

            .p2020 {
                position: absolute;
                width: 2.6rem;
                top: 2.485rem;
                left: 10.95rem;
                color: #555c66;
                font-size: 0.2rem;
                line-height: 0.335rem;
            }

            .year2021 {
                position: absolute;
                font-size: 0.2125rem;
                top: 6.455rem;
                left: 12.525rem;
                color: #fff;
            }

            .p2021 {
                position: absolute;
                width: 2.5rem;
                top: 6.05rem;
                left: 13.6rem;
                color: #555c66;
                font-size: 0.2rem;
                line-height: 0.335rem;
            }

            .year2022 {
                position: absolute;
                font-size: 0.2125rem;
                top: 1.27rem;
                left: 14.25rem;
                color: #fff;
            }

            .p2022 {
                position: absolute;
                width: 2.8125rem;
                top: 1.685rem;
                left: 14.65rem;
                color: #555c66;
                font-size: 0.2rem;
                line-height: 0.335rem;
            }

            .year2023 {
                position: absolute;
                font-size: 0.2125rem;
                top: 1.275rem;
                left: 18.033rem;
                color: #fff;
            }

            .p2023 {
                position: absolute;
                width: 3.4rem;
                top: 1.685rem;
                left: 18.45rem;
                color: #555c66;
                font-size: 0.2rem;
                line-height: 0.335rem;
            }

            .year2024 {
                position: absolute;
                font-size: 0.225rem;
                top: 5.375rem;
                left: 20.225rem;
                color: #fff;
            }

            .p2024 {
                position: absolute;
                width: 3.4rem;
                top: 4.5rem;
                left: 21rem;
                color: #555c66;
                font-size: 0.2rem;
                line-height: 0.335rem;
            }
        }
    }

    .certificate {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("../../assets/newImages/certificateBg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 12.5rem;
        margin-top: 0.25rem;

        .certificateTitle {
            font-size: 0.375rem;
            height: 0.5rem;
            background: linear-gradient(#fff 80%, #9bbbd6 100%);
            margin-top: 0.5rem;
            margin-bottom: -0.625rem;
            color: #2d5ea9;
            font-weight: bold;
        }

        .certificateImg {
            background-image: url("../../assets/newImages/certificate.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            height: 11.25rem;
            width: 16.25rem;
        }
    }

    .patent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .patentTitle {
            font-size: 0.375rem;
            height: 0.5rem;
            background: linear-gradient(#fff 80%, #9bbbd6 100%);
            margin-top: 0.5rem;
            color: #2d5ea9;
            font-weight: bold;
        }

        .triangle {
            display: inline-block;
            width: 0;
            height: 0;
            border: 0.125rem solid transparent; // 全部透明色
            border-bottom: 0.125rem solid #025696; // 让左边的边框变红色
            margin: 0.125rem 0;
        }

        .patentImgTitle {
            padding: 0.025rem;
            color: #025696;
            background: linear-gradient(to right, #e8eff6 0%, #fff 50%, #e8eff6);
        }
    }

    .partner {
        display: flex;
        background-color: #edf1f4;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        margin-top: 0.25rem;

        // background-image: url('~@/assets/newImages/partnerBg.jpeg');
        // background-repeat: no-repeat;
        // background-size: 100% 100%;
        // padding-top: .5rem;
        .partnerTitle {
            font-size: 0.375rem;
            height: 0.5rem;
            background: linear-gradient(#fff 80%, #9bbbd6 100%);
            margin-top: 0.5rem;
            color: #2d5ea9;
            font-weight: bold;
        }

        .partners {
            margin-top: 0.25rem;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 66%;

            .partnerItem {
                width: 18%;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 2%;
                margin-bottom: 2%;
                height: 1.5rem;
                transition: .3s;
                cursor: pointer;

                &:hover {
                    transform: translateY(-10px);
                    box-shadow: 0 0 10px rgba(27, 63, 130, .2);
                    border-radius: 5px
                }

                img {
                    width: 80%;
                    height: 80%;
                }
            }
        }
    }
}

.core {
    margin-top: 0.5rem;
    width: 66%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;

    .core-item {
        border: 0.0125rem solid #eee;
        background: linear-gradient(#f7fbfd 0%, #fff 100%);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0.25rem;
        box-sizing: border-box;
        transition: .3s;
        cursor: default;

        img {
            width: 0.375rem;
            height: 0.375rem;
        }

        .serialNumber {
            font-size: 0.5rem;
        }

        .title {
            font-size: 0.25rem;
            color: #000;
            font-weight: bold;
        }

        p {
            font-size: 0.2rem;
            color: #555c66;
            padding-top: 0.125rem;
            text-align: start;
            line-height: 0.375rem;
        }

        &:hover {
            background: #2c5eaa !important;
            transform: translateY(-12px);
            text-align: center;
            align-items: center;

            img,
            .serialNumber {
                display: none;
            }

            .title {
                color: #fff;
                margin-bottom: 10px;
            }

            p {
                color: #fff;
            }
        }
    }
}

.honor {
    display: flex;
    background-color: #ffffff;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 0.5rem;
    margin-bottom: 0.75rem;

    .honorTitle {
        font-size: 0.375rem;
        height: 0.5rem;
        background: linear-gradient(#ffffff 80%, #9bbbd6 100%);
        margin-top: 0.5rem;
        color: #2d5ea9;
        font-weight: bold;
    }
}
.mySwiper {
    margin-top: 0.75rem;
    width: 80%;
    position: relative;
    .img{
        width: 3.5rem;
        height: 4.8rem;
        border: 0.05rem solid #f7f7f7;
        margin: 0 auto;
        display: block;
    }
    .imgTitle{
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin: 0.25rem 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: normal;
    }

.swiper-button-prev {
    position: absolute;
    height: 0.625rem;
    width: 0.625rem;
    min-width: 0.375rem;
    min-height: 0.375rem;
    // background-color: #B9B9BB;
    top: 50%;
    left: -5%;

    &::after {
        color: #bcbcbc;
    }
}
.swiper-button-next {
    height: 0.75rem;
    width: 0.75rem;
    min-width: 0.5625rem;
    min-height: 0.5625rem;
    // background-color: #B9B9BB;
    position: absolute;
    top: 50%;
    right: -5%;

    &::after {
        color: #bcbcbc;
    }
}
}


.Softness {
    display: flex;
    background-color: #ffffff;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 0.5rem;
    margin-bottom: 0.75rem;

    .SoftnessTitle {
        font-size: 0.375rem;
        height: 0.5rem;
        background: linear-gradient(#ffffff 80%, #9bbbd6 100%);
        margin-top: 0.5rem;
        color: #2d5ea9;
        font-weight: bold;
    }
    .certify {
        margin-top: 0.75rem;
        width: 80%;
        position: relative;
        .swiper-slide{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            p{
                // background-color:#ddd;
                color:#777;
                text-shadow:2px 2px #fff;
                padding: 0.1rem;
                font-weight: bold;
                font-size: 0.2rem;
                width: 3.4rem;
                margin: 0 auto;
                text-align: center;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
            }
            img{
                width: 3.5rem;
                height: 4.8rem;
                border: 0.05rem solid #f7f7f7;
                margin: 0 auto;
                display: block;
            }
        }

        .swiper-button-prev {
            position: absolute;
            height: 0.625rem;
            width: 0.625rem;
            min-width: 0.375rem;
            min-height: 0.375rem;
            // background-color: #B9B9BB;
            top: 50%;
            left: -5%;

            &::after {
                color: #bcbcbc;
            }
        }
        .swiper-button-next {
            height: 0.75rem;
            width: 0.75rem;
            min-width: 0.5625rem;
            min-height: 0.5625rem;
            // background-color: #B9B9BB;
            position: absolute;
            top: 50%;
            right: -5%;

            &::after {
                color: #bcbcbc;
            }
        }
    }
}


.hoverLi .pic {
    overflow: hidden;
    height: 100%;
    white: 100%;
}

.hoverLi:hover .imgScale {
    transform: scale(1.2);
}

</style>
