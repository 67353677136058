<template>
  <div class="join">
    <div class="bigTitle">加入我们</div>
    <img src="@/assets/images/join.png" alt="" />
    <div class="nav" style="border-bottom: 0.0125rem solid #eeeeee">
      <div
        class="introduce"
        @click="joinFlag = 1"
        :class="{ joinActive: joinFlag == 1 }"
      >
        Java开发经理
      </div>
      <div
        class="streetList"
        @click="joinFlag = 2"
        :class="{ joinActive: joinFlag == 2 }"
      >
        项目经理（软件）
      </div>
    </div>
    <div class="joinItem" v-if="joinFlag == 1">
      <p class="join_top_title" style="color:#333333 ;">Java开发经理 1-1.5万/月</p>
      <p class="join_top_title" style="color:#333333 ;">
        深圳-龙岗区 | 3-4年经验 | 大专 | 招1人 |
        五险一金员工旅游年终奖金周末双休团建活动下午茶员工保险
      </p>
      <p class="joinItemTitle" style="color:#333333 ;">职位信息</p>
      <p style="color: #666666">
        1、负责研发部与项目部的沟通和业务方向确定工作;
        <br />
        2、负责研发部的人员管理和任务分配工作;
        <br />
        3、负责业务系统的分析、设计、建设和推广;
        <br />
        4、开展软件设计与建模,解决方案架构设计和交互策略制定工作;
        <br />
        5、制定和决策研发部的开发计划和技术发展方向;
      </p>
      <p class="joinItemTitle" style="color:#333333 ;">任职资格</p>
      <p style="color: #666666">
        1. 大专及以上学历,计算机或相关专业毕业;
        <br />
        2. 五年以上软件公司同等职位工作经验。
        <br />
        3.
        熟悉软件项目开发和管理的流程规范,至少具备3个项目完整的组织与实施经验,1年以上项目团队管理经验;
        <br />
        4. 熟悉java开发技能以及基本SQL技能;
        <br />
        5. 较强的学习能力和主动性,有事业心及良好的团队合作精神;
        <br />
        6. 优秀的沟通协调能力及项目推进能力;
        <br />
        7. 较强的责任心和自学能力,勤恳踏实的工作作风。
      </p>
    </div>

    <div class="joinItem" v-else>
      <p class="join_top_title" style="color:#333333 ;">项目经理(软件)1.8-2.5万/月</p>
      <p class="join_top_title" style="color:#333333 ;">深圳-龙岗区 | 5-7年经验 | 本科 | 招2人</p>
      <p class="joinItemTitle" style="color:#333333 ;">任职要求</p>
      <p style="color: #666666">
        1、统招本科及以上学历,计算机相关专业,5年以上Java软件从业经验,2年以上项目经理或团队管理经验;
        <br />
        2、熟悉政府项目流程及政府软件项目开发/实施/运维工作;
        <br />
        3、熟悉Oracle、Mysql等主流数据库;
        <br />
        4、良好的客户沟通和文档编写能力,能承受较强工作压力;
        <br />
        5、有大数据平台经验者优先考虑;
        <br />
        6、有电子政务类、智慧城市类相关项目经验者优先考虑。
      </p>
      <p class="joinItemTitle" style="color:#333333 ;">岗位职责</p>
      <p style="color: #666666">
        1、负责项目需求的调研与确认,制定工作计划、项目执行计划、人员配置计划、工作分解结构、成本计划等;
        <br />
        2、负责项目团队管理工作,统筹人员调度,对团队成员进行工作安排和督查,定期召开团队成员会议;
        <br />
        3、负责项目培训及实施上线工作,负责编写项目实施相关文档,包括需求开发、方案设计、产品实施和售后维护;
        <br />
        4、负责项目验收工作,项目结束时,进行结项工作,整理各种相关文档;
        <br />
        5、负责与项目相关的管理工作,负责整个项目干系人（客户、上级领导、团队成员等）之间关系的协调工作。
        技能要求：项目管理,PMP,实施,解决方案,需求分析
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      joinFlag: 1,
    };
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/styles/mixins.styl';

.active {
  border-right: 0.0375rem solid #04468D;
  color: #04468D !important;
}

.top {
  box-config(100%, 5.625rem, '../../assets/images/aboutTop.png');
  line-height: 5.625rem;

  .aboutP {
    color: #fff;
    font-size: 0.5rem;
    text-align: center;
  }
}

.con {
  padding: 0.975rem 0;
  position: relative;
  display: flex;
  width: 17.5rem;
  min-width: 15.5rem;

  @media screen and (max-width: 17.5rem) {
    width: 15.5rem;
  }

  min-height: 11.25rem;
  margin: 0 auto;
  margin-top: -0.625rem;
  background-color: white;
  border-radius: 0.1rem;
}

.left {
  padding: 0 1rem;

  .leftList {
    border-right: 0.0125rem solid #EEEEEE;
    margin-right: -0.0187rem;
  }

  .left_item {
    height: 0.45rem;
    line-height: 0.45rem;
    font-size: 0.2rem;
    margin-bottom: 0.375rem;
    width: 1.8rem;

    span {
      cursor: pointer;
    }
  }
}

.right {
  width: 12.25rem;
  min-width: 11.125rem;

  @media screen and (max-width: 17.5rem) {
    width: 11.125rem;
  }

  .advantage {
    .managementItem {
      display: flex;
      flex-direction: column;
      justify-content: inherit;
      background-color: #F8F8FA;
      // img
      // margin-bottom -0.125rem
      padding: 0;

      .youshi {
        height: auto;
        padding: 0.125rem;
      }
    }
  }

  .bigTitle {
    font-size: 0.375rem;
    font-weight: bold;
    color: #04468D;
  }

  .introduction {
    p {
      margin: 0.3125rem 0;
    }

    img {
      width: 100%;
    }
  }

  .framework {
    height: 9.375rem;
  }

  .frameworkList {
    padding-top: 1rem;
    height: 3.125rem;
    min-width: 11.5rem;
  }

  .frameworkBg {
    margin: 0 auto;
    position: relative;
    box-config(9.375rem, 2.2375rem, '../../assets/images/framework.png');

    .frameworkItem {
      position: absolute;
      height: 0.5rem;
      line-height: 0.5rem;
      text-align: center;
      width: 2rem;
      background-color: #707070;
      color: #fff;
      font-size: 0.225rem;
      border-radius: 0.0625rem;

      &:nth-child(1) {
        background-color: #333333;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &:nth-child(2) {
        top: 0.6rem;
        left: 1.325rem;
      }

      &:nth-child(3) {
        top: 0.6rem;
        left: 6.0875rem;
      }
    }
  }

  .UList {
    display: flex;
    justify-content: space-between;
    width: 11.5rem;
    margin: 0 auto;

    .UItem {
      &:nth-child(1) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#FD806C, #FDB27E);
      }

      &:nth-child(2) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#2C9EF9, #8FD9FF);
      }

      &:nth-child(3) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#FA73A9, #FCA284);
      }

      &:nth-child(4) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#5B66FF, #A4A4FF);
      }

      &:nth-child(5) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#0072EE, #98C8FB);
      }

      .UListItem {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        width: 2.125rem;
        font-size: 0.2rem;
        border-radius: 0.0625rem;
        margin-bottom: 0.4375rem;
        box-shadow: 0rem 0.0375rem 0rem 0.0125rem #eeeeee;
      }
    }
  }
}

.management {
  img {
    width: 100%;
    margin-top: 0.375rem;
  }
}

.managementList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .manTitle {
    font-size: 0.25rem;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      bottom: -0.125rem;
      left: 0rem;
      width: 0.4625rem;
      height: 0.0625rem;
      background-color: #5F9EE2;
    }
  }

  .managementItem {
    border: 0.0125rem solid #EEEEEE;
    border-radius: 0.0625rem;
    padding: 0.1875rem 0.125rem;
    margin-top: 0.75rem;
    width: 46%;
    line-height: 170%;

    img {
      width: 100%;
    }

    p {
      margin-top: 0.25rem;
    }
  }
}

.strength {
  .strengthList {
    display: flex;
    justify-content: space-around;
    margin-top: 0.625rem;

    .strengthItem {
      display: flex;

      img {
        width: 100%;
      }

      .strChild {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 0.175rem;
          height: 0.175rem;
          background-color: #C2DFFF;
          top: -0.0875rem;
          left: -0.0875rem;
        }
      }

      .strChildTitle {
        width: 1.625rem;
        font-size: 0.25rem;
        text-align: center;
        height: 0.5rem;
        line-height: 0.5rem;
        border: 0.0125rem solid #C4C4C4;
        margin-bottom: 0.625rem;
        position: relative;
        background-color: white;
        z-index: 1000;
      }
    }
  }
}

.join {
  font-size: 0.1875rem;

  .joinActive {
    color: #04468D;
    border-bottom: 0.0375rem solid #04468D;
  }

  img {
    width: 100%;
    height: auto;
    margin-top: 0.5rem;
  }

  .nav {
    display: flex;
    margin-top: 0.525rem;
    margin-bottom: 0.525rem;

    div {
      font-size: 0.25rem;
      height: 0.5rem;
      margin-right: 0.8rem;
      cursor: pointer;
    }
  }

  p {
    line-height: 185%;
  }

  .joinItemTitle {
    font-size: 0.225rem;
    margin-top: 0.35rem;
    color: #333333;
  }

  .join_top_title {
    font-size: 0.225rem;
  }
}

.contact {
  img {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.3125rem;
  }

  .contactList {
    margin: 1rem 0;
    display: flex;
    justify-content: space-around;

    img {
      width: auto;
      display: block;
      margin: 0 auto;
      margin-bottom: 0.3125rem;
    }
  }
}
</style>