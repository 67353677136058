<template>
  <div class="midContent">
    <News @isShow="isShow"></News>
    <!-- 多样的集成方案，满足业务所需 -->
    <div v-show="isNew" class="business">
      <div class="businessTitle">业务范围</div>
      <div class="businessProject">
        <div class="introduce">
          <div
            v-for="(item, index) in list"
            :key="item.id"
            class="introduceItem"
            @mouseenter="handleMouseEnter(index + 1)"
            :class="introduceIndex ==index+1 ? 'on' : ''"
            style="border-bottom: 0.0125rem solid #e8e8e8"
          >
            <div class="numericalOrder">0{{ index + 1 }}</div>
            <div class="introduceText" @click="goDetails(index)"
                :title="item.content">
              <div class="title">{{ item.title }}</div>
              <div class="text">
                {{ item.content }}
              </div>
            </div>
            <div v-show="introduceIndex == index + 1" class="mask">
              <p class="mask-title">
                <span>{{ item.title }}</span>
                <span class="info" @click="goDetails(index)">了解详情 >> </span>
              </p>
              <p class="mask-content" :title="item.content">{{ item.content }}</p>
            </div>
          </div>
        </div>
        <div class="introduceImg">
          <transition name="plus-icon" v-for="(item, index) in list" :key="index">
            <img
              v-show="introduceIndex == index + 1"
              :src="img(item.imgs)"
              alt
            />
          </transition>
        </div>
      </div>
    </div>
    <!-- 标杆案例,实践验证 -->
    <div v-show="isNew" class="case" id="caseInfo" name="caseInfo">
      <div class="caseTitle">案例展示</div>
      <!-- <div class="swiper1"> -->
      <div class="mySwiper">
        <div class="swiper">
          <!-- Additional required wrapper -->
          <div class="swiper-wrapper">
            <div class="swiper-slide"  v-for="item in caseInfo.caseLists" :key="item.id">
              <router-link :to="{path: '/detailsInfo', query: {id: item.id}}" v-scroll-to="'.block'">
                <img
                  :src="getURL(item.homeCaseImg, index)"
                  class="img"
                />
                <p class="title">{{item.title}}</p>
                <div class="keyBox">
                  <span class="keyWords" v-for="el in item.keyWords.slice(0, 3)" :key="el">
                      {{ el }}
                  </span>
                </div>
              </router-link>
            </div>
          </div>
          <!-- If we need navigation buttons -->
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
      <!-- </div> -->
      <!-- 携手伙伴，打造智慧一体化服务引领者 -->
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/bundle";
// import styles bundle
import axios from "axios";
import "swiper/css/bundle";
import News from "../NewsTrends/newsMainContent.vue";
import {pageMixin} from "@/mixins/index.js";
import caseInfo from "@/assets/data/caseInfo.json";
// import 'swiper/swiper-bundle.min.css'
export default {
  mixins: [pageMixin],
  components: {
    Swiper,
    News,
  },
  data() {
    return {
      caseInfo,
      introduceIndex: 1,
      isNew: true,
      list: [],
      // 首页案例展示
      caseList: []
    };
  },
  created() {
    this.getList();
    this.getCaseAllList();
    // this.toLocal()
    this.$nextTick(()=>{this.toLocal()})
  },
  mounted() {
    const swiper = new Swiper(".swiper", {
      // Optional parameters
      // direction: "vertical",
      loop: true,
      autoplay: {
        delay: 2000,
      },
      speed: 500, // 每一次滑动所需时间（单位为ms）
      slidesPerView: 5, //一行显示4个
      spaceBetween: 5, //间隔30
      // freeMode: true,
      speed: 300, //滑动速度
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
    let _this=this;
    this.$nextTick(function(){
        window.addEventListener('scroll',_this.handleScroll)
    })	
  },
  computed: {
    getURL(){
        return function(arr, i){
          if(Array.isArray(arr)){
            return require(`../../assets/newImages/${arr[i]}`);
          } else {
            return require(`../../assets/newImages/${arr}`);
          }

        }
    },
  },
  methods: {
    handleMouseEnter(index) {
      this.introduceIndex = index;
    },
    goDetailInfo(id) {
      this.$router.push(
        {
          path: "/detailsInfo",
          // name: "DetailsInfo",
          query: {
            id
          }
        }
      )
    },
    getList() {
      const _this = this;
      axios
        .get("/api/csWeb/articles?catalog=首页&categories=业务范围&orderBy=publishDate desc")
        .then(function (response) {
          _this.list = response.data.list;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    isShow(IS) {
      console.log(IS);
      this.isNew = !IS;
    },
    goDetails(index) {
      const pathList = [
        "/ProductPlan/Government/GovernmentCom",
        "/ProductPlan/Emergency/EmergencyCom",
        "/ProductPlan/Park/ParkCom",
        "/ProductPlan/Smart/SmartCom",
      ];
      this.$router.push(pathList[index]);
    },
    getCaseAllList(){
      axios
        .get("/api/csWeb/articles?catalog=产品方案")
        .then(res=>{
          this.caseList = res.data.list;
          console.log("全部案例", res.data.list);
        })
    },
    // 
    toLocal(){
      //查找存储的锚点id
      let Id=localStorage.getItem('toId');
      let toElement=document.getElementById(Id);
      //锚点存在跳转
      if(Id){
        toElement.scrollIntoView()
      }
    }
  },
  //用完后记得将存储的锚点置空，否则会影响其他页面跳转到当前页面
  destroyed() {
    localStorage.setItem('toId','');
  }
};
</script>

<style lang="stylus" scoped>
@import '../../assets/styles/mixins.styl';

.midContent {
  .introductory {
    display: flex;
    // justify-content: center
    // height: 6.25rem;
    background-color: #ffffff;
    flex-direction: column;
    align-items: center;
    padding-top: 0.5rem;

    .introductoryTitle {
      font-size: 0.375rem;
      color: #000;
    }

    .introductoryContent {
      margin-top: 0.5rem;
      width: 13.75rem;

      .introductoryContentImg {
        float: left;
        width: 40%;
        height: 4.375rem;
        background: url('../../assets/newImages/gate.png') no-repeat;
        background-size: contain;
      }

      .introductoryContentText {
        float: right;
        width: 8.25rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 0.375rem;
        box-sizing: border-box;

        .title {
          font-size: 0.25rem;
          color: #000;
          border-bottom: 0.0125rem solid #04468D;
          padding-bottom: 0.125rem;
        }

        .text {
          margin-top: 3%;
          font-size: 0.15rem;

          p {
            font-size: 0.175rem;
            text-indent: 2em;
          }
        }

        .link {
          text-align: center;
          font-size: 0.175rem;
          width: 1.125rem;
          height: 0.375rem;
          line-height: 0.375rem;
          border: 0.0125rem solid #04468D;
          color: #04468D;
          margin-top: 4%;
        }
      }
    }

    .introductoryData {
      display: flex;
      width: 13.75rem;
      height: 1.25rem;
      margin-top: 0.25rem;

      .introductoryDataItem {
        flex: 1;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;

        .number {
          height: 0.75rem;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            font-size: 0.625rem;
            color: #000000;

            span {
              font-size: 0.2rem;
            }
          }
        }

        .text {
          font-size: 0.15rem;
        }
      }
    }
  }

  .business {
    display: flex;
    background-color: #edf1f4;
    flex-direction: column;
    align-items: center;
    padding-top: 0.5rem;

    .businessTitle {
      // background: linear-gradient(to top, #a5c3da, #edf1f4, #edf1f4, #edf1f4);
      // color: #025696;
      // font-size: 0.375rem;
      font-size: 0.375rem;
      height: 0.5rem;
      background: linear-gradient(#edf1f4 80%, #9bbbd6 100%);
      margin-top: 0.5rem;
      color: #2d5ea9;
      font-weight: bold;
    }

    .businessProject {
      width: 15rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
      position: relative;
      
      .mask{
        cursor: default;
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 0;
        margin-bottom: 0.625rem;
        background-color: rgba(64, 158, 255, .8);
        width: 55%;
        height: 20%;
        z-index: 5;
        color: white;
        
        .mask-title{
          font-size: 0.3rem;
          font-weight: bold;
          padding: .3rem 0 0 .3rem;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          
          .info {
            font-size: 0.15rem;
            padding-right: .2rem;
            cursor: pointer;
            text-decoration: underline;
          }
        }
        .mask-content{
          font-size: 0.18rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 90%;
          margin: 0 auto;
          padding: 0.1rem 0 0 0.1rem;
          text-indent: 2ch;
          box-sizing: border-box;
        }
      }

      .introduce {
        // width 50%
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;


        .introduceItem {
          display: flex;
          margin-right: 0rem;
          cursor: pointer;
          padding: 0.425rem 0;
          justify-content: center;
          align-items: center;

          &:hover{
            .numericalOrder {
              -webkit-text-stroke: 2px #04468D !important;
            }
            .introduceText{
              color: #04468D !important;
            }
            //-webkit-text-stroke: 2px #04468D !important; // 根据需求不同改变颜色
            //color: #04468D !important;
          }
          // &:nth-child(-n + 4) {
          // border-bottom: .0125rem solid #e8e8e8;
          // padding-bottom: 0.125rem;
          // margin-bottom: 0.125rem;
          // }

          .numericalOrder {
            -webkit-text-stroke: 2px #8e9083; // 根据需求不同改变颜色
            -webkit-text-fill-color : transparent;
            //font-size: 30px;
            color:#fff;
            margin-right: 0.2rem;
            float: left;
            width: 20%;
            font-size: 1rem;
            //color: #9a9899;
            display :flex;
            justify-content: center;
            align-items: center;
          }

          .introduceText {
            float: left;
            width: 80%;

            .title {
              color: #000;
              font-size: 0.25rem;
              font-weight: 600;
              margin-bottom: 8px
            }

            .text {
              color: black;
              font-size: 0.22rem;
              line-height: 0.335rem;
              overflow :hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp:2;
              -webkit-box-orient:vertical;
            }

          }
        }

        .box {
          width: 10%;
          margin-top: 0.375rem;
          float: right;
          transform: translateX(30%);

          // border: .0125rem solid #000;
          // height: 0.625rem;

          .arrows {
            background-image: url('~@/assets/newImages/arrows.png');
            height: 0.625rem;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }

      .introduceImg {
        flex: 1;
        padding: 0.625rem;
        height: 7.5rem;
        position: relative;
        

        img {
          width: 120%;
          height: 100%;
          z-index: 1;
        }

      }
    }
  }

  .case {
    display: flex;
    background-color: #ffffff;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 0.3rem;
    padding-bottom: 1rem;
    box-sizing: border-box;

    // padding-top: 0.5rem;

    .caseTitle {
      // font-size: 0.375rem;
      // background: linear-gradient(to top, #a5c3da, #ffffff, #ffffff, #ffffff);
      // color: #025696;
      font-size: 0.375rem;
      height: 0.5rem;
      background: linear-gradient(#ffffff 80%, #9bbbd6 100%);
      margin: 0.5rem 0 0.3rem 0;
      color: #2d5ea9;
      font-weight: bold;
    }
    .keyBox{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      width: 98%;
      overflow: hidden;
      text-align: start;
      margin-top: 0.1rem;

      .keyWords{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.14rem;
        color: #4e4e4e;
        background-color: #fff;
        //border: 0.01rem solid #efefef;
        margin: 0.04rem;
        padding: 0.03rem; 
      }
    }
    .title{
      margin-top: 0.05rem;
      font-weight: bold;
      font-size: 0.19rem;
    }
  }

  .partner {
    display: flex;
    background-color: #edf1f4;
    flex-direction: column;
    align-items: center;
    background-image: url('~@/assets/newImages/partnerBg.jpeg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 0.5rem;

    .partnerTitle {
      font-size: 0.375rem;
      color: #000;
      margin-bottom: 0.5rem;
    }

    .partners {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 66%;

      .partnerItem {
        width: 18%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2%;
        margin-bottom: 2%;
        height: 1.5rem;

        img {
          width: 80%;
          height: 80%;
        }
      }
    }
  }

  .messageBoard {
    display: flex;
    background-color: #ffffff;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 2.75rem;
    padding-top: 0.5rem;

    .messageBoardTitle {
      font-size: 0.375rem;
      color: #000;
      margin-bottom: 0.5rem;
    }

    .messageBoardInput {
      .input {
        width: 7.5rem;
        height: 0.5rem;

        input {
          float: left;
          width: 86%;
          height: 100%;
          box-sizing: border-box;
        }

        .button {
          width: 14%;
          float: right;
          color: #fff;
          background-color: #285395;
          height: 100%;
          line-height: 0.5rem;
          text-align: center;
        }
      }

      .hint {
        display: flex;
        justify-content: space-between;

        .item {
          font-size: 0.175rem;
          margin-top: 0.1875rem;

          &:nth-child(1) > .icon {
            background-image: url('~@/assets/newImages/hintIcon1.png');
          }

          &:nth-child(2) > .icon {
            background-image: url('~@/assets/newImages/hintIcon2.png');
          }

          &:nth-child(3) > .icon {
            background-image: url('~@/assets/newImages/hintIcon3.png');
          }

          .icon {
            display: inline-block;
            width: 0.1875rem;
            height: 0.1875rem;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            transform: translateY(10%);
            margin-right: 0.025rem;
          }
        }
      }
    }
  }
}

.on {
  .numericalOrder{
    -webkit-text-stroke: 2px #04468D !important;
  }
  .introduceText {
    .title {
      color: #04468D !important;
    }

    .text {
      color: #04468D !important;
    }
  }
}

.mySwiper {
  margin-top: 0.2rem;
  width: 60%;
  position: relative;

  .swiper-button-prev {
    position: absolute;
    height: 0.625rem;
    width: 0.625rem;
    min-width: 0.375rem;
    min-height: 0.375rem;
    // background-color: #B9B9BB;
    top: 50%;
    left: -5%;
    transform: translateY(-50%);

    &::after {
      color: #bcbcbc;
    }
  }

  .swiper-button-next {
    height: 0.75rem;
    width: 0.75rem;
    min-width: 0.5625rem;
    min-height: 0.5625rem;
    // background-color: #B9B9BB;
    position: absolute;
    top: 50%;
    right: -5%;
    transform: translateY(-50%);

    &::after {
      color: #bcbcbc;
    }
  }

  .swiper {
    // background-color: red
    width: 100%;
    height: 4rem;
    // padding: 0.75rem 3.25rem;
    text-align: center;

    .swiper-wrapper {
      .swiper-slide {
        //transform: translateY(20%);
        transform: translateX(-8%);
        border: 0.0125rem solid #eee;
        padding-top: 0.125rem;
        padding-bottom: 0.25rem;
        height: 70%;
        background-color: #fff;
        position: relative;
        z-index: 20;

        img {
          // background-image: url('~@/assets/newImages/introduceImg1.png');
          width: 98%;
          height: 2.1rem;
          
        }
      }
    }
  }
}

.swiper-slide:hover {
  // background-color: pink;
  animation-name: scaleDraw; /* 关键帧名称 */
  animation-timing-function: linear; /* 动画的速度曲线 */
  animation-iteration-count: 1; /* 动画播放的次数 */
  animation-duration: 0.3s; /* 动画所花费的时间 */
  animation-fill-mode: forwards;
  border: 0.025rem solid #eee;
  box-shadow: 0 0 0.0625rem rgba(0, 0, 0, 0.1);

  a {
    display: inline !important;
  }
}

.swiper-wrapper {
}

@keyframes scaleDraw {
  /* 定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称 */
  0% {
    transform: scale(1) translateX(-8%);
    z-index: 299;
    height: 70%;
  }

  25% {
    transform: scale(1.02) translateX(-8%);
    z-index: 299;
    height: 71%;
  }

  50% {
    transform: scale(1.04) translateX(-8%);
    z-index: 299;
    height: 73%;
  }

  75% {
    transform: scale(1.07) translateX(-8%);
    z-index: 299;
    height: 74%;
  }

  100% {
    transform: scale(1.1) translateX(-8%);
    z-index: 299;
    height: 76%;
  }
}

.plus-icon-enter-active {
  transition: opacity 0.5s;
}

.plus-icon-enter {
  opacity: 0;
}

.plus-icon-enter-to {
  opacity: 1;
}

.plus-icon-leave-active {
  opacity: 0;
}

.plus-icon-leave-to {
  opacity: 0;
}
</style>
