<template>
  <div>
    <router-view class="rou_view"></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.title {
  font-size: 0.375rem;
  font-weight: bold;
  color: #04468D;
}

.rou_view {
  color: #333333;
}
</style>
