<template>
  <div id="newsDetail">
    <div class="mainContent">
      <div class="topNav">
        <div>
          <div class="navItem" @click="sendDetailStatus" style="z-index: 9999">
            公司动态
          </div>
          <span
            style="
              margin: 0 0.125rem;
              color: #000;
              transform: translateY(-0.025rem);
            "
            >/</span
          >
          <div class="navItem active">详细信息</div>
        </div>
        <!-- <div @click="sendDetailStatus" style="z-index: 9999">返回</div> -->
      </div>
      <div class="mainBox">
        <div class="titleBar">
          {{ curDetlObj.title }}
        </div>
        <div class="date" v-if="curDetlObj.createDate">
          <!-- 发布时间：{{ curDetlObj.year }}-{{ curDetlObj.month }}-{{
            curDetlObj.day
          }} -->
          {{ curDetlObj.createDate }}
        </div>
        <div class="textPart">
          {{ curDetlObj.content }}
        </div>
        <div class="imgList">
          <div
            class="imgItem"
            :class="{width100:curDetlObj.swiperImgList.length==1}"
            v-for="(item, index) in curDetlObj.swiperImgList"
            :key="index"
          >
            <img :src="getImgUrl(item)"  />
          </div>
        </div>
      </div>
    </div>
    <div class="shortcut">
      <div class="back p" @click="sendDetailStatus" v-scroll-to="'#anchor'">
        <span>返&nbsp;&nbsp;&nbsp;&nbsp;回</span
        ><img src="../../../assets/newImages/返回.png" />
      </div>
      <div
        class="next p"
        @click="update(curDetlObj.index + 1)"
        v-show="curDetlObj.nextTitle != '无'"
      >
        <span>下一篇</span><img src="../../../assets/newImages/下一篇.png" />
      </div>
    </div>
  </div>
</template>
<script>
import { ImgUrl } from "@/unitils/doMain";
export default {
  name: "newsDetail",
  props: {
    curDetlObj: {
      type: Object,
      default: {},
    },
  },
  computed: {
    getImgUrl() {
      return (img) => {
        return ImgUrl+img
      };
    },
  },
  methods: {
    sendDetailStatus() {
      this.$emit("getDetailStatus", false);
    },
    update(index) {
      this.$emit("upDatecurDetlObj", index);
    },
  },
  mounted() {
    console.log(ImgUrl, "{{ ImgUrl }}{{ ImgUrl }}{{ ImgUrl }}");
  },
};
</script>
<style lang="less" scoped>
#newsDetail {
  width: 100%;
  background-color: #f6f6f6;
  position: relative;
  .mainContent {
    margin: 0 auto;
    width: 56%;
    display: flex;
    flex-direction: column;

    .topNav {
      width: 100%;
      height: 0.625rem;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        display: flex;
        // flex-direction: column;
        .navItem {
          cursor: pointer;
          color: black;
          font-size: 0.1875rem;
        }

        .active {
          cursor: default;
          color: #035795;
        }
      }
    }

    .mainBox {
      width: 100%;
      // height: 11.75rem;
      background-color: white;
      padding: 0.5625rem 0.5625rem;
      margin-bottom: 0.75rem;
      box-sizing: border-box;

      .titleBar {
        width: 100%;
        font-size: 0.25rem;
        font-weight: bold;
        color: black;
        text-align: center;
        margin-bottom: 0.1875rem;
      }

      .date {
        font-size: 0.1875rem;
        color: #c0c0c0;
        text-align: center;
        margin-bottom: 0.3125rem;
      }

      .textPart {
        text-indent: 0.25rem;
        font-size: 0.2rem;
        line-height: 0.375rem;
        color: #777d85;
        margin-bottom: 0.25rem;
      }

      .imgList {
        margin-top: 0.4125rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .imgItem {
          width: 49.5%;
          padding: 0 0.025rem 0.0625rem 0;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .paging {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
.shortcut {
  position: fixed;
  top: 6.2rem;
  right: 3.9rem;
  .p {
    cursor: pointer;
    height: 0.525rem;
    border-radius: 0.525rem;
    width: 1.25rem;
    font-size: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    background-color: #dce1f4;
    img {
      width: 0.375rem;
      height: 0.375rem;
      margin-left: 0.05rem;
    }
  }
  .p:hover {
    background-color: #78b3f7;
  }
  .back {
    background-color: #dce1f4;
  }
  .next {
    margin-top: 0.0375rem;
  }
}
.width100{
  width: 90% !important;
}
</style>
