import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
//main.js
import axios from "axios";

Vue.config.productionTip = false;
import "@/unitils/flexible.js";
import "@/assets/styles/asset.styl";
import "@/assets/styles/common.styl";
import "@/assets/styles/mixins.styl";
import "@/assets/css/iconfont/iconfont.css";
import VueScrollTo from "vue-scrollto";
Vue.prototype.$axios = axios;
Vue.use(VueScrollTo, {
  offset: -50,
});
Vue.use(ElementUI);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
