<template>
  <div class="MachineRoom" style="margin-bottom: 0.375rem;">
    <div class="top">
      <div class="fs-20 mar_top my-title">机房工程</div>
      <router-link to="/ProductPlan/Smart">
        <div class="back-btn">
          <span class="iconfont icon-fanhui"></span><span>返回</span>
        </div>
      </router-link>
      <div class="content">
        <p class="fs-16" style="line-height: 0.375rem;">
          机房工程是指为确保计算机机房（也称数据中心)的关键设备和装置能安全、稳定和可靠运行而设计配置的基础工程，计算机机房基础设施
          的建设不仅要为机房中的系统设备运营管理和数据信息安全提供保障环境，还要为工作人员创造健康适宜的工作环境。
        </p>
        <div class="top_img">
          <img src="@/assets/newImages/MachineRoom_01.png" alt="" />
        </div>
        <!-- <div class="top_img">
          <img src="@/assets/newImages/MachineRoom_02.png" alt="" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.top {
  p {
    margin-bottom: 0.25rem;
  }

  .top_line {
    width: 12.25rem;

    img {
      width: 100%;
    }
  }

  .mar_top {
    margin-top: 0.25rem;
  }

  .content {
  }
}

.top_img {
  width: 12.25rem;

  img {
    margin-top: 0.25rem;
    width: 100%;
  }
}
</style>>