<template>
  <div>
    <!-- <div class="title">数字政务</div> -->
    <div class="govermentList">
      <div class="project_item">
        <router-link to="/ProductPlan/Emergency/EmManagement">
          <img src="@/assets/newImages/EmManagement_01.png" alt="" />
          <p>智慧应急综合管理平台</p>
        </router-link>
      </div>
      <div class="project_item">
        <router-link to="/ProductPlan/Emergency/EmFireControl">
          <img src="@/assets/images/fireControl02.png" alt="" />
          <p>智慧消防</p>
        </router-link>
      </div>
      <div class="project_item">
        <router-link to="/ProductPlan/Emergency/EmForest">
          <img src="@/assets/images/515.png" alt="" />
          <p>智慧森林防火</p>
        </router-link>
      </div>
      <div class="project_item">
        <router-link to="/ProductPlan/Emergency/EmService">
          <img src="@/assets/newImages/EmService_02.png" alt="" />
          <p>应急物资一体化服务</p>
        </router-link>
      </div>
    </div>
<!--    <div class="nav">-->
<!--      <router-link to="/ProductPlan/Emergency/EmergencyCom/EmManagement" active-class="active">-->
<!--        <div class="streetList">智慧应急综合管理平台</div>-->
<!--      </router-link>-->
<!--      <router-link to="/ProductPlan/Emergency/EmergencyCom/EmFireControl" active-class="active">-->
<!--        <div class="streetList">智慧消防</div>-->
<!--      </router-link>-->
<!--      <router-link to="/ProductPlan/Emergency/EmergencyCom/EmForest" active-class="active">-->
<!--        <div class="streetList">智慧森林防火</div>-->
<!--      </router-link>-->
<!--      <router-link to="/ProductPlan/Emergency/EmergencyCom/EmDetect" active-class="active">-->
<!--        <div class="streetList">北斗形变探测</div>-->
<!--      </router-link>-->
<!--      <router-link to="/ProductPlan/Emergency/EmergencyCom/EmService" active-class="active">-->
<!--        <div class="streetList">应急物资一体化服务</div>-->
<!--      </router-link>-->
<!--    </div>-->
<!--    <router-view></router-view>-->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="stylus" scoped>
@import '../../../../assets/styles/mixins.styl';

.title {
  font-size: 30px;
  font-weight: bold;
  color: #04468D;
}

.nav {
  border-bottom: 1px #EEEEEE solid;
  display: flex;
  margin-top: 0.525rem;
  justify-content: space-between;
  color: #999;

  div {
    font-size: 0.25rem;
    height: 0.5rem;
  }

  .streetList {
    text-align: center;
    color: #999999;
  }
}

.active {
  border-bottom: 3px solid #0072EE;

  .streetList {
    text-align: center;
    color: #333333 !important;
  }
}

.title {
  font-size: 30px;
  font-weight: bold;
  color: #04468D;
}

.govermentList {
  display: grid;
  grid-template-columns repeat(3,1fr)
  gap:30px 5%;

  .project_item {
    position: relative
    font-size: 0.225rem;
    transition .3s;
    &:hover{
      transform:translateY(-10px);
      box-shadow 0 0 10px #0005;
      &:after{
        opacity:1
      }
      p{

        background-color: #2C5EAA;
        color: #fff
      }
    }
    img {
      width: 100%;
      height: 135px;
    }

    p {
      height: 0.5rem;
      line-height: 0.5rem;
      box-shadow: 0px 3px 10px 0px rgba(101, 101, 101, 0.11);
      margin-top: -6px;
      text-align: center;
      font-size:0.25rem;
    }
  }
}
</style>
