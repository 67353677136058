<template>
  <div>
    <div class="govermentList">
      <div
        v-for="(item, index) in digitalGovernmentList"
        :key="item.id"
        class="project_item"
        @click="godetail(item)"
      >
        <div class="iconPng">
          <img :src="img(item.imgs)" alt="" />
        </div>
        <p>{{ item.title }}</p>
      </div>


      <div
        v-for="(item, index) in wisdomDevelopment"
        :key="item.id"
        class="project_item"
        @click="godetail(item)"
      >
        <div class="iconPng">
          <img :src="img(item.imgs)" alt="" />
        </div>
        <p>{{ item.title }}</p>
      </div>

      <div
        v-for="(item, index) in wisdomTravel"
        :key="item.id"
        class="project_item"
        @click="godetail(item)"
      >
        <div class="iconPng">
          <img :src="img(item.imgs)" alt="" />
        </div>
        <p>{{ item.title }}</p>
      </div>

      <div
        v-for="(item, index) in smartLand"
        :key="item.id"
        class="project_item"
        @click="godetail(item)"
      >
        <div class="iconPng">
          <img :src="img(item.imgs)" alt="" />
        </div>
        <p>{{ item.title }}</p>
      </div>

      <div
        v-for="(item, index) in smartStreet"
        :key="item.id"
        class="project_item"
        @click="godetail(item)"
      >
        <div class="iconPng">
          <img :src="img(item.imgs)" alt="" />
        </div>
        <p>{{ item.title }}</p>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import { ImgUrl } from "@/unitils/doMain";

export default {
  data() {
    return {
      // 数字政务
      digitalGovernmentList: [],
      // 智慧发改
      wisdomDevelopment: [],
      // 智慧文旅
      wisdomTravel: [],
      // 智慧土地
      smartLand: [],
      // 智慧街道
      smartStreet: [],
    };
  },
  created() {
    this.getList();
  },
  computed: {
    img() {
      return (imgs) => {
        return ImgUrl + imgs.split(":")[0];
      };
    },
  },

  methods: {
    // 跳转详情页面
    godetail(data) {
      this.$router.push({
        path: "/ProductPlan/Government/details/0",
        query: data,
      });
    },

    getList() {
      const _this = this;
      axios
        .get("/api/csWeb/articles?catalog=产品方案&categories=智慧政务")
        .then(function (response) {
          console.log(response, "ewew");
          response.data.list.forEach((element) => {
            switch (element.type) {
              case "数字政务":
                _this.digitalGovernmentList.push(element);
                break;
              case "智慧发改":
                _this.wisdomDevelopment.push(element);
                break;
              case "智慧土地":
                _this.smartLand.push(element);

                break;
              case "智慧文旅":
                _this.wisdomTravel.push(element);
                break;
              case "智慧街道":
                _this.smartStreet.push(element);
                break;
              default:
                break;
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    goTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../../../assets/styles/mixins.styl';
.title {
  font-size: 0.375rem;
  font-weight: bold;
  color: #04468D;
}
.govermentList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.375rem;
  justify-content:start;


  .project_item {
    margin-bottom: .375rem;
    margin-left:.375rem;
    width: 3.5rem;
    font-size: 0.25rem;
    transition .3s;
    &:hover{
      transform:translateY(-10px);
      box-shadow 0 0 10px #0005;
      &:after{
        opacity:1
      }
      p{
        background-color: #2C5EAA;
        color: #fff
      }
    }
    .iconPng{
      width 3.5rem;
      height:1.7rem;
      img {
        width: 100%;
        height: 100%;

      }
    }


    p {
      height: 0.5rem;
      line-height: 0.5rem;
      box-shadow: 0px 3px 10px 0px rgba(101, 101, 101, 0.11);
      // margin-top: -6px;
      text-align: center;
      font-size:0.25rem;
    }
  }
}
</style>
