/**
 * ImgUrl 图片拼接的地址
 * req 请求接口的地址
 */
const environment = {
  // 开发
  development: {
    ImgUrl: "http://139.9.71.176:20080",
    // reqUrl:'http://139.9.71.176:80/'
  },

  // 商务演示
  official: {
    ImgUrl: "http://139.9.71.176:20080",
  },
};

module.exports = environment["development"];
