<template>
  <div class="BAControl" style="margin-bottom: 0.375rem;">
    <div class="top">
      <div class="fs-20 mar_top my-title">BA控制系统</div>
      <router-link to="/ProductPlan/Smart">
        <div class="back-btn">
          <span class="iconfont icon-fanhui"></span><span>返回</span>
        </div>
      </router-link>
      <div class="content">
        <p class="fs-16" style="line-height: 0.375rem;">
          BA系统将通信、计算机和自控等技术通过有效的信息传输网路，将建筑物内的电力、照明、空调、电梯、给排水、消防等设备或系统连接起
          来，各子系统之间信息相互联动，实现对建筑物内所有机电设备运行状态的监控和自动化管理，保证建筑物内所有设备均处于高效、节能和
          最佳运行状态，为用户提供良好的工作和生活环境。
        </p>
        <p class="fs-16" style="line-height: 0.375rem;">
          为实现对设备的灵活控制以及集中管理，采用集散控制系统(DCS)。集散控制系统可划分为三级结构:第一级为现场控制级，承担单个设备控制
          以及与监控站联系;第二级为监控级，主要对建筑设备控制信息进行集中管理;第三级为管理级，将建筑设备自动化系统和整体智能建筑系统有
          机结合，构成安全的监控管理系统。
        </p>
        <div class="top_img">
          <img src="@/assets/newImages/BAControl_01.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.top {
  p {
    margin-bottom: 0.25rem;
  }

  .top_line {
    width: 12.25rem;

    img {
      width: 100%;
    }
  }

  .mar_top {
    margin-top: 0.25rem;
  }

  .content {
  }
}

.top_img {
  width: 12.25rem;

  img {
    margin-top: 0.25rem;
    width: 100%;
  }
}
</style>>