<template>
  <div class="emergency">
    <!-- <div class="title">智慧应急</div> -->
    <div class="govermentList">
      <div
        v-for="(item, index) in list"
        :key="item.id"
        class="project_item"
        @click="godetail(item)"
      >
        <div class="iconPng">
          <img :src="img(item.imgs)" alt="" />
        </div>
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { pageMixin } from "@/mixins/index.js";
export default {
  mixins: [pageMixin],
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const _this = this;
      axios
        .get("/api/csWeb/articles?catalog=产品方案&categories=智慧应急")
        .then(function (response) {
          _this.list = response.data.list;
          // console.log(response.data.list, "智慧应急");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.emergency {
  @media screen and (max-width: 1400px) {
    width: 105%;
  }
}

.title {
  font-size: 0.375rem;
  font-weight: bold;
  color: #04468D;
  margin-bottom: 30px
}

.rou_view {
  color: #333333;
}
// .title {
//   font-size: 30px;
//   font-weight: bold;
//   color: #04468D;
// }

.nav {
  border-bottom: 1px #EEEEEE solid;
  display: flex;
  margin-top: 0.525rem;
  justify-content: space-between;
  color: #999;

  div {
    font-size: 0.25rem;
    height: 0.5rem;
  }

  .streetList {
    text-align: center;
    color: #999999;
  }
}

.active {
  border-bottom: 3px solid #0072EE;

  .streetList {
    text-align: center;
    color: #333333 !important;
  }
}

// .title {
//   font-size: 30px;
//   font-weight: bold;
//   color: #04468D;
// }

.govermentList {
  display: grid;
  grid-template-columns repeat(3,1fr)
  gap:30px 5%;

  .project_item {
    position: relative
    font-size: 0.225rem;
    transition .3s;
    &:hover{
      transform:translateY(-10px);
      box-shadow 0 0 10px #0005;
      &:after{
        opacity:1
      }
      p{

        background-color: #2C5EAA;
        color: #fff
      }
    }
    img {
      width: 100%;
      height: 135px;
    }

    p {
      height: 0.5rem;
      line-height: 0.5rem;
      box-shadow: 0px 3px 10px 0px rgba(101, 101, 101, 0.11);
      margin-top: -6px;
      text-align: center;
      font-size:0.25rem;
    }
  }
}
</style>
