<template>
  <div>
    <!-- <div class="top">
      <div class="topTitle"> -->
        <!-- <img src="@/assets/newImages/CustBannerTitle.png" alt="" /> -->
      <!-- </div>
      <div class="topImg"> -->
        <!-- <img src="@/assets/newImages/CustBannerImg.png" alt="" /> -->
      <!-- </div>
    </div> -->

    <div class="address">
      <!-- <div class="title">联系我们</div> -->
      <div style="width: 100%; height: 0.5rem">
        <!-- <div
          style="
            width: 10%;
            height: 0.5rem;
            border-top: 0.0375rem solid #2d5ea9;
            margin: 0 auto;
          "
        ></div> -->
      </div>
      <div class="map">
        <map-container ref="mapContainer"></map-container>
        <div class="addressItemSZ" @click="switchAddress('sz')">
          <div class="name">
            <div class="icon"></div>
            公司地址
          </div>
          <div class="text">深圳市龙岗区园山街道大运软件小镇39栋3楼</div>
          <div class="info">
            <div class="phoneNumber">
              <img src="../../assets/newImages/phone.png" alt="" />
              <div>
                <p>0755-28248405</p>
              </div>
            </div>
            <div class="website">
              <img
                style="
                  height: 0.275rem;
                  width: 0.3375rem;
                  margin-left: -0.025rem;
                "
                src="../../assets/newImages/website.png"
                alt=""
              />
              <div>
                <p>www.szcshl.com</p>
              </div>
            </div>
            <div class="openingHours">
              <img src="../../assets/newImages/openingHours.png" alt="" />
              <div>
                <p>transmi@szcshl.com</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import MapContainer from "../About/MapContainer.vue";

export default {
  name: "conMainContent",
  components: { MapContainer },
  methods: {
    switchAddress(address) {
      this.$refs.mapContainer.switchAddress(address);
    },
  },
};
</script>
<style lang='less' scoped>
@import "@/assets/styles/mixins.less";

.top {
  position: relative;
  .box-config(100%, 4.875rem, "../../assets/newImages/联系我们-背景.png");

  .topTitle {

    position: absolute;
    width: 60%;
    height: 100%;
    transform: translateX(25%) translateY(12%);
    background-image: url("../../assets/newImages/关于我们-文字.png");
    background-size: 100% 100%;
  }

}


.address {
  background-color: #fff;
  padding-bottom: 0.5rem;

  .title {
    width: 100%;
    height: 1rem;
    background-color: #fff;
    font-size: 0.375rem;
    line-height: 1rem;
    // text-align: center;
    border-bottom: 0.0125rem solid #eee;
    color: #2d5ea9;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-style: oblique;

    span {
      // border-bottom: 0.0375rem solid #2d5ea9;
      padding-bottom: 0.3rem;
    }
  }

  .map {
    width: 60%;
    margin: 0 auto;

    // border: 0.1rem solid #fff;

    // box-shadow: 0 0 0.0625rem 0.0375rem #dee8f1;
    position: relative;

    .addressItemSZ {
      width: 3rem;
      background: linear-gradient(#fff 0%, #ecf3fc 100%);
      height: 3.25rem;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      padding: 0.25rem;
      border: 0.025rem solid #fff;
      box-shadow: 0 0 0.0625rem 0.0375rem #dee8f1;

      .name {
        font-size: 0.25rem;
        color: #000;
        line-height: 100%;

        .icon {
          width: 0.25rem;
          height: 0.1875rem;
          background-image: url("../../assets/newImages/addressIcon.png");
          background-size: 100% 100%;
          float: left;
        }
      }

      .text {
        // padding: .25rem;
        margin-top: 0.3rem;
        font-size: 0.2rem;
      }
      .info {
        margin-top:.25rem ;
        .website {
          margin: 0.325rem 0 0.175rem;
        }

        .openingHours {
          margin-top: 0.325rem;
        }

        img {
          float: left;
          margin-right: 0.25rem;
          height: 0.3125rem;
          width: 0.3125rem;
        }

        p {
          font-size: 0.2rem;
        }
      }
    }

    .addressItemLN {
      width: 3rem;
      background: linear-gradient(#fff 0%, #ecf3fc 100%);
      height: 1.25rem;
      position: absolute;
      top: 2.5rem;
      left: 0.5rem;
      padding: 0.25rem;
      border: 0.025rem solid #fff;
      box-shadow: 0 0 0.0625rem 0.0375rem #dee8f1;

      .name {
        font-size: 0.225rem;
        color: #000;
        line-height: 100%;

        .icon {
          width: 0.25rem;
          height: 0.1875rem;
          background-image: url("../../assets/newImages/addressIcon.png");
          background-size: 100% 100%;
          float: left;
        }
      }

      .text {
        // padding: .25rem;
        margin-top: 0.3rem;
        font-size: 0.175rem;
      }
    }
  }
}
</style>
