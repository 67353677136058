<template>
  <div class="management">
    <div class="bigTitle">经营理念</div>
    <img src="@/assets/images/management.png" alt="" />
    <div class="managementList">
      <div class="managementItem">
        <div class="manTitle" style="color:#333333 ;">软件为主</div>
        <p style="line-height: 0.375rem;">
          新型智慧城市总包方基本上只负责顶层设计和硬件基础设施建
          设，不具备大规模的软件实施能力，需要大量的软件实施单位
          作为支持。我公司定位是在细分领域内成为优秀的<a style="color:#333333 ;">智慧城市相关的软件服务提供商。</a>
        </p>
      </div>
      <div class="managementItem">
        <div class="manTitle" style="color:#333333 ;">合作为赢</div>
        <p style="line-height: 0.375rem;">
          新型智慧城市是超大型的项目，需要各种服务商的介入，我们
          积极与友商合作，已经与中国电科、中科软、软通动力等大公
          司建立良好的合作伙伴关系，“善利薄争，互联共兴”是公司 命名之源。
        </p>
      </div>
      <div class="managementItem">
        <div class="manTitle" style="color:#333333 ;">业务为长</div>
        <p style="line-height: 0.375rem;">
          重视沉淀核心业务领域的细分领域，逐步成为该领域的业务专
          家，目前团队已经非常熟悉发改委相关业务流程,未来定位<a style="color:#333333 ;">发展为街道业务、文体旅游局业务、消防安防业务</a>三大块。
        </p>
      </div>
      <div class="managementItem">
        <div class="manTitle" style="color:#333333 ;">服务为根</div>
        <p style="line-height: 0.375rem;">
          忠实坚定执行“软件即服务”的口号，服务包括线上与线下。
          业务能力涵盖政务信息化项目的全流程，包括项目前期策划、
          立项、初步设计、概算到项目的研发实施售后运维，能完美解
          决客户的实际需求，提升服务品质。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
@import '../../assets/styles/mixins.styl';

.active {
  border-right: 0.0375rem solid #04468D;
  color: #04468D !important;
}

.top {
  box-config(100%, 5.625rem, '../../assets/images/aboutTop.png');
  line-height: 5.625rem;

  .aboutP {
    color: #fff;
    font-size: 0.5rem;
    text-align: center;
  }
}

.con {
  padding: 0.975rem 0;
  position: relative;
  display: flex;
  width: 17.5rem;
  min-width: 15.5rem;

  @media screen and (max-width: 17.5rem) {
    width: 15.5rem;
  }

  min-height: 11.25rem;
  margin: 0 auto;
  margin-top: -0.625rem;
  background-color: white;
  border-radius: 0.1rem;
}

.left {
  padding: 0 1rem;

  .leftList {
    border-right: 0.0125rem solid #EEEEEE;
    margin-right: -0.0187rem;
  }

  .left_item {
    height: 0.45rem;
    line-height: 0.45rem;
    font-size: 0.2rem;
    margin-bottom: 0.375rem;
    width: 1.8rem;

    span {
      cursor: pointer;
    }
  }
}

.right {
  width: 12.25rem;
  min-width: 11.125rem;

  @media screen and (max-width: 17.5rem) {
    width: 11.125rem;
  }

  .advantage {
    .managementItem {
      display: flex;
      flex-direction: column;
      justify-content: inherit;
      background-color: #F8F8FA;
      // img
      // margin-bottom -0.125rem
      padding: 0;

      .youshi {
        height: auto;
        padding: 0.125rem;
      }
    }
  }

  .bigTitle {
    font-size: 0.375rem;
    font-weight: bold;
    color: #04468D;
  }

  .introduction {
    p {
      margin: 0.3125rem 0;
    }

    img {
      width: 100%;
    }
  }

  .framework {
    height: 9.375rem;
  }

  .frameworkList {
    padding-top: 1rem;
    height: 3.125rem;
    min-width: 11.5rem;
  }

  .frameworkBg {
    margin: 0 auto;
    position: relative;
    box-config(9.375rem, 2.2375rem, '../../assets/images/framework.png');

    .frameworkItem {
      position: absolute;
      height: 0.5rem;
      line-height: 0.5rem;
      text-align: center;
      width: 2rem;
      background-color: #707070;
      color: #fff;
      font-size: 0.225rem;
      border-radius: 0.0625rem;

      &:nth-child(1) {
        background-color: #333333;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &:nth-child(2) {
        top: 0.6rem;
        left: 1.325rem;
      }

      &:nth-child(3) {
        top: 0.6rem;
        left: 6.0875rem;
      }
    }
  }

  .UList {
    display: flex;
    justify-content: space-between;
    width: 11.5rem;
    margin: 0 auto;

    .UItem {
      &:nth-child(1) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#FD806C, #FDB27E);
      }

      &:nth-child(2) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#2C9EF9, #8FD9FF);
      }

      &:nth-child(3) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#FA73A9, #FCA284);
      }

      &:nth-child(4) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#5B66FF, #A4A4FF);
      }

      &:nth-child(5) .UListItem:nth-child(1) {
        color: #fff;
        background-image: linear-gradient(#0072EE, #98C8FB);
      }

      .UListItem {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        width: 2.125rem;
        font-size: 0.2rem;
        border-radius: 0.0625rem;
        margin-bottom: 0.4375rem;
        box-shadow: 0rem 0.0375rem 0rem 0.0125rem #eeeeee;
      }
    }
  }
}

.management {
  font-size: 0.175rem;

  img {
    width: 100%;
    margin-top: 0.375rem;
  }
}

.managementList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .manTitle {
    font-size: 0.25rem;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      bottom: -0.125rem;
      left: 0rem;
      width: 0.4625rem;
      height: 0.0625rem;
      background-color: #5F9EE2;
    }
  }

  .managementItem {
    border: 0.0125rem solid #EEEEEE;
    border-radius: 0.0625rem;
    padding: 0.1875rem 0.125rem;
    margin-top: 0.75rem;
    width: 46%;
    line-height: 170%;

    img {
      width: 100%;
    }

    p {
      margin-top: 0.25rem;
    }
  }
}

.strength {
  .strengthList {
    display: flex;
    justify-content: space-around;
    margin-top: 0.625rem;

    .strengthItem {
      display: flex;

      img {
        width: 100%;
      }

      .strChild {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 0.175rem;
          height: 0.175rem;
          background-color: #C2DFFF;
          top: -0.0875rem;
          left: -0.0875rem;
        }
      }

      .strChildTitle {
        width: 1.625rem;
        font-size: 0.25rem;
        text-align: center;
        height: 0.5rem;
        line-height: 0.5rem;
        border: 0.0125rem solid #C4C4C4;
        margin-bottom: 0.625rem;
        position: relative;
        background-color: white;
        z-index: 1000;
      }
    }
  }
}

.join {
  .joinActive {
    color: #04468D;
    border-bottom: 0.0375rem solid #04468D;
  }

  img {
    width: 100%;
    height: auto;
    margin-top: 0.5rem;
  }

  .nav {
    display: flex;
    margin-top: 0.525rem;
    margin-bottom: 0.525rem;

    div {
      font-size: 0.25rem;
      height: 0.5rem;
      margin-right: 0.8rem;
      cursor: pointer;
    }
  }

  p {
    line-height: 185%;
  }

  .joinItemTitle {
    font-size: 0.225rem;
    margin-top: 0.35rem;
  }
}

.contact {
  img {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.3125rem;
  }

  .contactList {
    margin: 1rem 0;
    display: flex;
    justify-content: space-around;

    img {
      width: auto;
      display: block;
      margin: 0 auto;
      margin-bottom: 0.3125rem;
    }
  }
}
</style>