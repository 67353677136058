<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: 'ContactUs'
}
</script>
<style lang='less' scoped></style>