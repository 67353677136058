<template>
  <div id="app">
    <Header></Header>
    <Swiper></Swiper>
    <router-view/>
    <!-- <div class="footer_top"></div> -->
    <Footer></Footer>
  </div>
</template>

<script>

import Header from "@/components/Header";
import Footer from "@/components/Footer";
// 轮播组件
import Swiper from "@/components/topSwiper"
export default {
  name: "app",
  components: {
    Header,
    Footer,
    Swiper
  },
  data() {
    return {};
  },
  mounted() {

  },
  watch: {
      $route(to, from) {
        if(to.path == '/detailsInfo'){
          window.location.reload(); //监测到路由发生跳转时刷新一次页面
        }
      },
  },
};
</script>

<style lang="stylus">
#app {
  overflow-x: hidden;
  background-color: #F8F8FA;
}

// .footer_top {
//   margin-top: 80px;
// }
</style>
