<template>
  <div>
    <!-- <div class="title">智能化工程</div> -->
    <div class="govermentList">
      <div
        v-for="(item, index) in list"
        :key="item.id"
        class="project_item"
        @click="godetail(item)"
      >
        <div class="iconPng">
          <img :src="img(item.imgs)" alt="" />
        </div>
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { pageMixin } from "@/mixins/index.js";
export default {
  mixins: [pageMixin],
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const _this = this;
      axios
        .get("/api/csWeb/articles?catalog=产品方案&categories=智能化工程")
        .then(function (response) {
          console.log(response,'85858');
          _this.list = response.data.list;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
}
</script>

<style lang="stylus" scoped>
.title {
  font-size: 0.375rem;
  font-weight: bold;
  color: #04468D;
}
.govermentList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.375rem;
  justify-content:start;


  .project_item {
    margin-bottom: .375rem;
    margin-left:.375rem;
    width: 3.5rem;
    font-size: 0.25rem;
    transition .3s;
    &:hover{
      transform:translateY(-10px);
      box-shadow 0 0 10px #0005;
      &:after{
        opacity:1
      }
      p{
        background-color: #2C5EAA;
        color: #fff
      }
    }
    .iconPng{
      width 3.5rem;
      height:1.7rem;
      img {
        width: 100%;
        height: 100%;

      }
    }


    p {
      height: 0.5rem;
      line-height: 0.5rem;
      box-shadow: 0px 3px 10px 0px rgba(101, 101, 101, 0.11);
      // margin-top: -6px;
      text-align: center;
      font-size:0.25rem;
    }
  }
}
</style>

