<template>
  <div id="newsMainContent">
    <!-- <div class="topBanner">
            <div class="topTitle">
                <img src="@/assets/newImages/newsBannerTitle.png" alt="">
            </div>
        </div> -->
    <div class="mainContent" id="mainContent" v-show="!detailStatus">
      <div class="topNav">
        <div class="navListBox" id="anchor">
          <div
            class="navItem"
            :class="actIndex == '0' ? 'active' : ''"
            @click="getDetailList('0')"
            style="cursor: default"
          >
            公司动态
          </div>
          <!-- <div class="navItem" :class="actIndex == '1' ? 'active' : ''" @click="getDetailList('1')">行业新闻</div> -->
        </div>
      </div>
      <div class="companyNewsBox">
        <div class="leftBox">
          <img :src="getImgUrl(comNewsList[0].swiperImgList[0])" alt=""/>
        </div>
        <div class="rightBox">
          <div class="newsTitle">{{ comNewsList[0].title }}</div>
          <div class="timer" style="font-size: 0.2rem">
            <!-- {{ comNewsList[0].year }}-{{ comNewsList[0].month }}-{{
              comNewsList[0].day
            }} -->
            {{ comNewsList[0].createDate }}
          </div>
          <div
            class="textPart"
            style="
              font-size: 0.22rem;
              text-indent: 2em;
              height: 2.5575rem;
              line-height: 0.375rem;
            "
          >
            {{ comNewsList[0].content }}
          </div>
          <div class="timerOrDetail">
            <div
              class="detailBtn"
              @click="curDetlListchange(comNewsList[0], 0)"
            >
              <span>详细信息</span>
              <span
              ><img src="@/assets/newImages/arrows_r_white.png" alt=""
              /></span>
            </div>
          </div>
        </div>
      </div>
      <div class="detailList">
        <div
          v-for="(detailItem, index) in detailList"
          :key="index"
          v-show="index <= detailItemNumber"
        >
          <div class="newsItem" v-if="index != 0">
            <div class="dateBox">

              <div class="day"> {{ detailItem.publishDate.split(' ')[0].split('-')[2] }}</div>
              <div class="yearM">
                {{ detailItem.publishDate.split(' ')[0].split('-')[0] }} -
                {{ detailItem.publishDate.split(' ')[0].split('-')[1] }}
              </div>
            </div>
            <div class="textBox">
              <div class="newsTitle" @click="curDetlListchange(detailItem, index)"
                   style="cursor: pointer; font-size: 0.22rem">
                {{ detailItem.title }}
              </div>
              <div
                class="textPart"
                @click="curDetlListchange(detailItem, index)"
                style="cursor: pointer; font-size: 0.22rem">
                {{ detailItem.content }}
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            padding-left: 8%;
            transform: translateX(16.5625rem) translateY(0.125rem);
            color: #04468d;
            cursor: pointer;
          "
          @click="showMore"
          v-if="detailItemNumber < detailList.length"
        >
          查看更多
        </div>
        <div
          style="
            padding-left: 10%;
            transform: translateX(16.5625rem) translateY(0.125rem);
            color: #04468d;
            cursor: pointer;
          "
          @click="showMore(false)"
          v-if="detailItemNumber >= detailList.length"
        >
          收起
        </div>
      </div>
      <!-- 分页器 -->
      <div class="pagination">
        <div class="pageItem">
          <!-- <el-pagination background small layout="pager" :total="70"> </el-pagination> -->
        </div>
      </div>
    </div>
    <!-- 详细信息 -->
    <div class="mainContent" v-show="detailStatus">
      <newsDetail
        @getDetailStatus="getDetailStatus"
        :curDetlObj="curDetlObj"
        @upDatecurDetlObj="upDatecurDetlObj"
      />
    </div>
  </div>
</template>
<script>
import Swiper from "swiper/bundle";
import {ImgUrl} from "@/unitils/doMain";
import newsDetail from "@/views/NewsTrends/components/newsDetail.vue";
// import styles bundle
import "swiper/css/bundle";
import axios from "axios";

export default {
  name: "newsMainContent",
  components: {newsDetail},
  data() {
    return {
      actIndex: "0",
      detailStatus: false,
      // indexDetlObj:0,
      comNewsList: [
        // {
        //   swiperImgList: [
        //     {
        //       img: require("@/assets/newImages/newsImg20.png"),
        //     },
        //     {
        //       img: require("@/assets/newImages/newsImg21.png"),
        //     },
        //     {
        //       img: require("@/assets/newImages/newsImg22.png"),
        //     },
        //     {
        //       img: require("@/assets/newImages/newsImg23.png"),
        //     },
        //   ],
        //   routeName: "公司新闻",
        //   title: "踏浪前行 | 创势2023年度年中会议成功召开",
        //   content:
        //     "为回顾过去半年取得的成绩，夯实下半年工作基础，2023年6月30日，创势成功召开上半年年度工作会议。2023上半年创势上下同心聚力，完善运营管理机制，重塑品牌文化与品牌建设，为客户提供的数字政务一体化服务赢得市场信任。下半年各部门的工作目标与运营机制将继续不断深入，细化任务指标，明确责任分工，相信在创势所有人的共同努力下，下半年一定能实现业绩突破与业务能力的提升。",
        //   year: "2023",
        //   month: "06",
        //   day: "30",
        // },
        // {
        //   swiperImgList: [
        //     {
        //       img: require("@/assets/newImages/newsImg1.png"),
        //     },
        //     {
        //       img: require("@/assets/newImages/office1.jpg"),
        //     },
        //     {
        //       img: require("@/assets/newImages/office2.jpg"),
        //     },
        //     {
        //       img: require("@/assets/newImages/office3.jpg"),
        //     },
        //   ],
        //   routeName: "公司新闻",
        //   title: "新起点、新征程！ — —创势互联乔迁新办公区！",
        //   content:
        //     "2023年01月08日，创势互联迎来了发展史上的又一里程碑——乔迁新址。7年来，创势互联全体同仁在公司领导人阳总的带领下，团结进取，上下一心，迎来了公司成长过程中的一个又一个春天。这次公司乔迁至大运软件小镇39栋3楼，办公面积扩大到近700㎡，除温馨舒适的办公区域外，还设有员工活动室、多功能会议厅、员工休息区、贵宾接待室、小会议室、餐厅等，整个办公区宽敞明亮，高端大气。无论从硬件设施还是软件环境都得到了大幅度提升。优越的工作环境，为公司的持续发展提供了新的动力，激励着每一个创势人更加勤奋地工作，激情地奉献，为创势互联的发展再立新功。",
        //   year: "2023",
        //   month: "01",
        //   day: "08",
        // },
        // {
        //   swiperImgList: [
        //     // {
        //     //   img: require("@/assets/newImages/gate.png"),
        //     // },
        //     {
        //       img: require("@/assets/newImages/团建1.jpg"),
        //     },
        //     {
        //       img: require("@/assets/newImages/团建2.jpg"),
        //     },
        //   ],
        //   routeName: "公司新闻",
        //   title: "筑梦七载 砥砺前行 | 创势公司七周年庆",
        //   content:
        //     "有一种相逢叫携手同行，有一种感动叫风雨兼程！2023年3月19日，创势迎来了7周年生日，创势全体同仁齐聚庆贺，高举筑梦之火，传递梦想！七年砥砺，铸就辉煌！经过7年的探索与发展，创势不仅实现自己从0到1的进步，从无到有的改变，共同见证了中国信息化行业的成长与发展。7周年对于创势来说，是纪念过去的里程碑，也是展望未来的新起点，精彩才刚刚开始！",
        //   year: "2023",
        //   month: "03",
        //   day: "19",
        // },

        // {
        //   swiperImgList: [
        //     {
        //       img: require("@/assets/newImages/newsImg2.png"),
        //     },
        //     {
        //       img: require("@/assets/newImages/newsImg3.png"),
        //     },
        //     {
        //       img: require("@/assets/newImages/newsImg4.png"),
        //     },
        //     {
        //       img: require("@/assets/newImages/newsImg5.png"),
        //     },
        //   ],
        //   routeName: "公司新闻",
        //   title: "2022年创势公司年终晚宴温暖收官",
        //   content:
        //     "山舞银蛇，原驰蜡象。千峰笋石千株玉，万树松萝万朵银，最是一年好冬景。籍此2022年春节来临之际，创势公司年终晚宴温暖盛启，全体创势家人欢一堂，共享阖欢家宴。晚宴上，公司董事长阳总与各高管对2022年做出年终总结与2023年公司运营工作做出规划，并对优秀员工进行了表彰。",
        //   year: "2023",
        //   month: "01",
        //   day: "10",
        // },
      ],
      tradeNewsList: [
        {
          swiperImgList: [
            {
              img: require("@/assets/newImages/newsImg8.png"),
            },
            {
              img: require("@/assets/newImages/newsImg7.png"),
            },
            {
              img: require("@/assets/newImages/newsImg9.png"),
            },
            {
              img: require("@/assets/newImages/newsImg10.png"),
            },
          ],
          routeName: "行业新闻",
          title: "龙华区综合应急物资仓库正式揭牌启用！",
          content:
            "6月8日下午，龙华区综合应急物资仓库揭牌仪式成功举办，标志着龙华区在全市率先高标准建成应急救援、森林防火、三防物资“三库合一”综合应急物资仓库。深圳市应急管理局副局长刘建军同志，龙华区应急管理局主要负责同志，分管负责同志，区相关部门和各街道负责同志等出席此次活动。龙华区综合应急物资仓库实储面积4503平方米，目前已存放276种近100万件物资仓库，实现应急救援、森林防火、三防物资“三库合一”仓储模式，并与京东供应链达成战略合作，形成了社会共建应急物资混储管理体系。此外，龙华区将物资储备情况录入全国应急物资管理系统，深圳市应急管理综合管理平台，并将区应急物资与街道应急物资接入自主研发系统，建立起基于国家、市、区、街道的四级应急物资管理体系。",
          year: "2023",
          month: "06",
          day: "08",
        },
        {
          swiperImgList: [
            {
              img: require("@/assets/newImages/newsImg11.png"),
            },
          ],
          routeName: "行业新闻",
          title: "智慧城市让生活更美好 | 新知！",
          content:
            "某种程度上，城市的智能化程度是城市发展水平与核心竞争力的重要体现。广东深圳实现“一图全面感知、一号走遍深圳、一键可知全局、一体运行联动、一站创新创业、一屏智享生活”，数据的有效连接与智能交互打破了不同部门、领域之间的壁垒，破解了治理碎片化难题；上海徐汇推动“一云汇数据、一屏观徐汇、一网治全城、一人通全岗”，跨部门、跨层级、跨区域的城市运营管理推动数据联通、服务联结、治理联动。实践充分证明，一个能看、能用、能思考的智慧城市运行和指挥中枢，既连接起经济、生态、交通、公共安全、城市治理等信息数据，也能打通司法、安监、消防、环保等业务系统，方便了人们的生活，提升了城市治理现代化水平。",
          year: "2022",
          month: "04",
          day: "01",
        },
        {
          swiperImgList: [
            {
              img: require("@/assets/newImages/newsImg12.png"),
            },
          ],
          routeName: "行业新闻",
          title: "推广 | 企业数字化转型：突破资本与人才瓶颈是关键！",
          content:
            "城市金融体系建设对于促进产业转型发展具有决定性作用。目前各地为加快推动产业的数字化转型，纷纷设立政策资金。然而，伴随数字化前沿技术的迅速渗透，企业数字化转型所面临的资本要素制约日趋明显。当前我国各城市数字化要素保障体系不完善，众多企业在数字化转型过程中仍面临严重的资金短缺问题，一定程度上制约了企业生产力水平，难以满足企业数字化转型需求。根据对2016年1月至2018年7月互联网、人工智能及大数据等领域数字企业风险投资监测数据发现，我国数字经济企业的资本要素集聚度高，主要分布在东部数字经济较为发达的地区，其中北京、广东、上海、浙江、江苏、福建、天津和山东等八个省（市）的风险投资额占比高达97.14%。可见，东部地区作为我国经济相对发达地区，融资渠道较为通畅，发达的金融市场为数字企业发展提供了充裕的资金支持以获取市场竞争优势。",
          year: "2018",
          month: "10",
          day: "15",
        },
      ],
      detailList: [],
      curDetlObj: {
        swiperImgList: [],
      },
      //展示新闻条数
      detailItemNumber: 2,
    };
  },
  methods: {
    getComNewsList() {
      const _this = this;
      this.comNewsList = [];
      axios
        .get("/api/csWeb/articles?categories=公司动态&orderBy=publishDate desc")
        .then(function (response) {
          response.data.list.forEach((element) => {
            _this.comNewsList.push({
              ...element,
              swiperImgList: element.imgs.split(":").slice(0, -1),
            });
          });
          // _this.comNewsList = response.data.list;
          // console.log(response.data.list, "公司动态");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //获取新闻在线列表
    getNewinfoList() {
      axios
        .get("/api/a/article/articleManagement/dataList")
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //获取新闻列表数据
    getDetailList(status) {
      if (status == "0") {
        this.detailList = this.comNewsList; //详情列表
      } else {
        this.detailList = this.tradeNewsList; //详情列表
      }
      this.actIndex = status; //导航互动状态
      this.curDetlObj = {
        ...this.detailList[0],
        index: 0,
        lastTitle: "无",
        nextTitle: this.comNewsList[1].title || "无",
      }; //当前轮播详情列表
    },
    //修改当前轮播详情列表数据
    curDetlListchange(detailItem, index) {
      this.curDetlObj = {
        ...detailItem,
        index,
        lastTitle: this.comNewsList[index - 1]?.title || "无",
        nextTitle: this.comNewsList[index + 1]?.title || "无",
      };

      this.detailStatus = true;
      //  var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //  console.log(scrollTop);
      window.scrollTo(0, 200);
    },
    //获取子组件的展示状态
    getDetailStatus(status) {
      this.detailStatus = status;
    },
    //更新数据
    upDatecurDetlObj(index) {
      console.log(index, this.comNewsList.length);
      if (index >= 0 && index < this.comNewsList.length) {
        console.log("ppp");
        this.curDetlObj = {
          ...this.comNewsList[index],
          index: index,
          lastTitle: this.comNewsList[index - 1]?.title || "无",
          nextTitle: this.comNewsList[index + 1]?.title || "无",
        };

        // window.location.hash = "#mainContent"
        let options = {
          top: 0,
          behavior: "smooth",
        };
        window.scrollTo(options);
      }
    },
    //展示更多
    showMore(isAdd = true) {

      console.log(this.detailList.length);
      if (isAdd) {
        this.detailItemNumber = this.detailList.length
      } else {
        this.detailItemNumber = 2;
      }
      // this.detailItemNumber=this.detailItemNumber+3;
    },
  },
  mounted() {
    this.getComNewsList();
    this.getDetailList("0");

    // this.getNewinfoList()
  },
  computed: {
    getImgUrl() {
      return (img) => {
        return ImgUrl + img
      };
    },
  },
  watch: {
    detailStatus: {
      handler(newval, oldval) {
        this.$emit("isShow", newval);
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/styles/mixins.less";

::v-deep .el-pagination {
  .el-pager {
    .active {
      background-color: #025696 !important;
    }

    li {
      background-color: white !important;
    }

    .number {
      color: #025696;
      border: 0.0125rem solid #025696;
    }
  }
}

#newsMainContent {
  width: 100%;

  // height: 37.5rem;
  // transform: translateX(3%);
  .topBanner {
    position: relative;
    .box-config(100%, 7.875rem, "../../assets/newImages/newsBanner.png");

    .topTitle {
      position: absolute;
      transform: translateX(1.1875rem) translateY(0.3125rem);
      height: 7.5rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .mainContent {
    background-color: white;
    display: flex;
    flex-direction: column;

    .topNav {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 1.875rem;

      .navListBox {
        display: flex;

        .navItem {
          cursor: pointer;
          width: 1.625rem;
          height: 0.625rem;
          line-height: 0.625rem;
          text-align: center;
          margin: 0 0.375rem;
          font-size: 0.375rem;
          color: #bababa;
        }

        .active {
          // height: 0.625rem;
          // background: linear-gradient(
          //   to top,
          //   #a5c3da,
          //   #ffffff,
          //   #ffffff,
          //   #ffffff
          // );
          // color: #025696;
          font-size: 0.375rem;
          height: 0.55rem;
          background: linear-gradient(#ffffff 80%, #9bbbd6 100%);
          margin-top: 0.5rem;
          color: #2d5ea9;
          font-weight: bold;
        }
      }
    }

    .companyNewsBox {
      width: 100%;
      transform: translateX(3%);

      .timer {
        font-size: 0.25rem;
        color: #025696;
        margin-left: 26%;
      }

      .leftBox {
        float: left;
        width: 48%;
        height: 3.75rem;
        background-color: white;
        overflow: hidden;

        img {
          transform: translateX(-0.875rem);
          float: right;
          width: 59%;
          object-fit: cover;
        }
      }

      .rightBox {
        float: left;
        width: 49%;
        background-color: white;

        .newsTitle {
          padding: 0 0.375rem 0.375rem 0.375rem;
          color: black;
          font-size: 0.26rem;
          font-weight: 600;
          margin-left: 5%;
        }

        .textPart {
          width: 68%;
          height: 2.2375rem;
          color: #000;
          font-size: 0.175rem;
          line-height: 0.275rem;
          overflow: hidden;
          display: -webkit-box;
          /* autoprefixer: off*/
          -webkit-box-orient: vertical;
          /* autoprefixer: on*/
          -webkit-line-clamp: 8;
          text-overflow: ellipsis;
        }

        .timerOrDetail {
          display: flex;
          justify-content: space-between;
          width: 67%;
          height: 0.875rem;
          margin-top: 0.125rem;

          .timer {
            font-size: 0.15rem;
            color: #025696;
          }

          .detailBtn {
            cursor: pointer;
            width: 1.5rem;
            height: 0.375rem;
            line-height: 0.375rem;
            border: 0.0125rem solid #2d5ea9;
            border-radius: 0.0625rem;
            background-color: #2d5ea9;
            font-size: 0.25rem;
            color: white;
            display: flex;
            margin-left: 80%;

            span {
              padding-left: 0.125rem;
            }

            & :nth-child(2) {
              img {
                width: 0.225rem;
              }
            }
          }
        }
      }
    }

    .detailList {
      transform: translateX(3%);

      .newsItem {
        margin-left: 16%;

        // cursor: pointer;

        height: 2.25rem;
        width: 65.5%;
        border-bottom: 0.0125rem dashed #cfc3c1;
        display: flex;
        justify-content: center;
        align-items: center;

        .dateBox {
          position: relative;
          border: 0.0125rem solid white;
          border-radius: 0.0625rem;
          width: 1.475rem;
          height: 1.475rem;
          margin-right: 0.375rem;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0.0125rem 0.0125rem 0.0625rem 0.0375rem #dee8f1;
          background: linear-gradient(to top, #eef2fb, #ffffff);

          .day {
            font-size: 0.7rem;
            color: black;
          }

          .yearM {
            color: #025696;
            font-size: 0.15rem;
            position: absolute;
            top: 1.1rem;
            right: 0.35rem;
            background-color: #eff4fc;
          }
        }

        .textBox {
          width: 90%;
          height: 1.475rem;

          .newsTitle {
            margin: 0 0 0.25rem 0;
            color: black;
            font-size: 0.26rem;
            font-weight: 600;
          }

          .textPart {
            color: #000;
            font-size: 0.175rem;
            line-height: 0.375rem;
            overflow: hidden;
            display: -webkit-box;
            /* autoprefixer: off*/
            -webkit-box-orient: vertical;
            /* autoprefixer: on*/
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
          }

          .textPart:hover {
            color: #025696 !important;
          }
        }
      }
    }

    .pagination {
      margin: 0.25rem 0 1rem 0;
      display: flex;
      justify-content: flex-end;

      .pageItem {
        margin-right: 27%;
      }
    }
  }
}
</style>
