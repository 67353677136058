import { ImgUrl } from "@/unitils/doMain";
export const pageMixin = {
  data() {
    return {};
  },
  computed: {
    img() {
      return (imgs) => {
        return ImgUrl + imgs.split(":")[0];
      };
    },
  },

  methods: {
    // 跳转详情页面
    godetail(data) {
        this.$router.push({
          path: "/ProductPlan/Government/details/0",
          query: data,
        });
      },
  },
};
