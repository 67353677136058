<template>
  <div class="FireControl">
    <div class="top">
      <div class="mar_top my-title font20">综合布线系统</div>
      <router-link to="/ProductPlan/Smart">
        <div class="back-btn">
          <span class="iconfont icon-fanhui"></span><span>返回</span>
        </div>
      </router-link>
      <div class="content">
        <p class="font16" style="width: 100%;">
          综合布线系统可划分成七个子系统︰工作区子系统、配线(水平)子系统、干线(垂直)子系统、设备间子系统、管理子系统、建筑群子系统、
          光缆传输系统。
        </p>
        <div class="top_img_flex">
          <div class="top_img">
            <img src="@/assets/newImages/Wiring_01.png" alt="" />
          </div>
          <div class="top_img_2">
            <img src="@/assets/newImages/Wiring_02.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.top {
  p {
    width: 9.9375rem;
    height: 0.6375rem;
  }

  .top_line {
    width: 12.25rem;

    img {
      width: 100%;
    }
  }

  .mar_top {
    margin-top: 0.25rem;
  }

  .content {
    p {
      margin-top: 0.125rem;
    }
  }
}

.top_img_flex {
  display: flex;
  justify-content: space-between;

  .top_img {
    width: 6.2rem;

    img {
      margin-top: 0.25rem;
      width: 100%;
    }
  }

  .top_img_2 {
    width: 5.5625rem;

    img {
      margin-top: 0.25rem;
      width: 100%;
    }
  }
}
</style>
>
